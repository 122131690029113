import { Injectable } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Observable, of as observableOf, throwError as observableThrowError, zip } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import {
  LOGIN_EMAIL_EXISTS_ERROR,
  LOGIN_EMAIL_FORM_FIELD,
  MODULE_ACCOUNT_CREATION_PAGE,
} from '@app/core/mixpanel.constants';
import { UserService } from '@app/core/user.service';
import { StepName } from '@app/registration/enterprise/registration-step-name';
import { Address } from '@app/shared/address';
import { SendAppLinkGraphQLService } from '@app/shared/download-app-card/send-app-link-graphql.service';
import { User } from '@app/shared/user';

import { CreateAccountStepComponent } from '../../common/create-account/create-account-step.component';
import { ValidateEmailService } from '../../validate-email.service';
import { ValidatePasswordService } from '../../validate-password.service';
import { EnterpriseRegistration } from '../enterprise-registration';
import { EnterpriseRegistrationAnalyticsService } from '../enterprise-registration-analytics.service';
import { IRegistrationStep, RegistrationStep } from '../registration-step';

@Injectable()
export class CreateAccountConfig extends RegistrationStep implements IRegistrationStep {
  GA_LABEL = 'Create_Your_Account_Step';

  MODULE = MODULE_ACCOUNT_CREATION_PAGE;
  captchaNeeded = true;
  component = CreateAccountStepComponent;
  componentInstance: CreateAccountStepComponent;
  progress = 60;
  address: Address;

  form = this.formBuilder.group({
    logInEmail: ['', Validators.required],
    password: ['', Validators.required],
  });

  constructor(
    private enterpriseRegistrationAnalyticsService: EnterpriseRegistrationAnalyticsService,
    private emailService: ValidateEmailService,
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private passwordService: ValidatePasswordService,
    private sendAppLinkGraphQLService: SendAppLinkGraphQLService,
  ) {
    super();
  }

  canGoBack() {
    return true;
  }

  initComponent(component: CreateAccountStepComponent, state: EnterpriseRegistration) {
    component.form = this.form;
    component.trackResetPasswordClicked = () =>
      this.enterpriseRegistrationAnalyticsService.resetPasswordClicked({
        module: this.MODULE,
        isWhitelist: state.isWhitelisted,
        companyId: state.b2bCompanyId,
        serviceArea: state.serviceArea.name,
      });

    component.trackEmailAdminClicked = () =>
      this.enterpriseRegistrationAnalyticsService.emailAdminClicked({
        module: this.MODULE,
        isWhitelist: state.isWhitelisted,
        companyId: state.b2bCompanyId,
        serviceArea: state.serviceArea.name,
      });

    this.componentInstance = component;
  }

  onDestroy() {
    this.form.controls.logInEmail.clearValidators();
    this.form.controls.password.clearValidators();
  }

  submit(state: EnterpriseRegistration, captcha: any): Observable<any> {
    if (this.form.invalid) {
      return observableThrowError(new Error());
    }
    const { logInEmail, password } = this.form.value;
    state.patient.email = logInEmail;
    state.patient.password = password;
    return this.createNewUser(captcha, logInEmail, state, password);
  }

  private createNewUser(captcha: any, logInEmail: any, state: EnterpriseRegistration, password: any) {
    const uniqueness = captcha.getToken().pipe(
      switchMap((reCaptchaToken: string) =>
        this.emailService.validateUniqueness(logInEmail, reCaptchaToken).pipe(
          map(() => true),
          catchError((err: string) => {
            this.componentInstance.emailError = err;
            this.trackUniquenessError(state);
            return observableOf(false);
          }),
        ),
      ),
    );
    const complexity = this.passwordService.validateComplexity(logInEmail, password).pipe(
      map(() => true),
      catchError((err: string) => {
        this.componentInstance.passwordError = err;
        return observableOf(false);
      }),
    );

    return zip(uniqueness, complexity).pipe(
      switchMap(([unique, complex]) => {
        if (unique && complex) {
          this.trackSubmission(state);
          return captcha.getToken().pipe(
            switchMap((reCaptchaToken: string) =>
              this.userService.createUser(state.patient, { recaptchaToken: reCaptchaToken }),
            ),
            catchError(err => {
              this.componentInstance.passwordError = err;
              return observableThrowError(err);
            }),
          );
        } else {
          throw new Error();
        }
      }),
      tap((user: User) => {
        this.finishSubmit(state, user);
      }),
    );
  }

  private finishSubmit(state: EnterpriseRegistration, user: User) {
    // save id for later use in registration steps
    state.patient.id = user.id;
    const { phoneNumber, textAppToPhone } = state.form.value.accountSetUp;
    if (textAppToPhone) {
      this.sendAppLinkGraphQLService
        .mutate({
          input: {
            phoneNumber: phoneNumber,
          },
        })
        .subscribe();
    }
    this.enterpriseRegistrationAnalyticsService.identifyAndUpdateUser(user);
    state.setCurrentStep(StepName.termsOfService);
  }

  private trackSubmission(state: EnterpriseRegistration) {
    this.enterpriseRegistrationAnalyticsService.regInputSubmitted({
      isWhitelist: state.isWhitelisted,
      module: this.MODULE,
      relationshipType: state.details.membershipType,
    });
    this.enterpriseRegistrationAnalyticsService.trackWithLaunchDarkly({
      key: 'Enterprise Registration Completion',
    });
  }

  private trackUniquenessError(state: EnterpriseRegistration) {
    this.enterpriseRegistrationAnalyticsService.regInputErrored({
      error: LOGIN_EMAIL_EXISTS_ERROR,
      formField: LOGIN_EMAIL_FORM_FIELD,
      isWhitelist: state.isWhitelisted,
      companyId: state.b2bCompanyId,
      module: this.MODULE,
    });
  }
}
