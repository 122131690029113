import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'om-modal-template',
  templateUrl: './modal-template.component.html',
  styleUrls: ['./modal-template.component.scss'],
})
export class ModalTemplateComponent implements OnInit {
  @Input() heading: string;
  @Input() subheader: string;
  @Input() primaryCallToActionLabel?: string;
  @Input() secondaryCallToActionLabel?: string;
  @Input() contentClasses = 'd-md-block d-sm-none d-none text-center pb-4';
  @Input() primaryClasses = 'modal-footer pt-0 px-md-5 border-0 justify-content-center';
  @Input() secondaryClasses = 'modal-body text-center pt-0 py-3';
  @Input() showCloseButton = true;
  @Input() hideModalBody = false;
  @Input() primaryCallToActionDisabled = false;

  protected showPrimaryCta: boolean;
  protected showSecondaryCta: boolean;

  @Output() close: EventEmitter<void> = new EventEmitter<void>();
  @Output() primaryCallToAction: EventEmitter<void> = new EventEmitter<void>();
  @Output() secondaryCallToAction: EventEmitter<void> = new EventEmitter<void>();

  ngOnInit() {
    this.showPrimaryCta = !!this.primaryCallToActionLabel;
    this.showSecondaryCta = !!this.secondaryCallToActionLabel;
  }
}
