import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { LinksService } from '@app/core/links.service';
import { PatientRegistrationStatusGraphqlService } from '@app/registration/patient-registration-status-graphql.service';

@Injectable({
  providedIn: 'root',
})
export class RegistrationIncompleteGuardService {
  constructor(
    private links: LinksService,
    private router: Router,
    private patientRegistrationStatusGraphQLService: PatientRegistrationStatusGraphqlService,
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.patientRegistrationStatusGraphQLService
      .fetch()
      .pipe(map(result => !result.data?.patient?.isRegComplete || this.router.parseUrl(this.links.home)));
  }
}
