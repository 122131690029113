import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { MfaToMarkRegCompleteEdge } from '@app/registration/graph/edges/mfa-to-mark-reg-complete.edge';
import { NodeUrlMappingService } from '@app/registration/graph/node-url-mapping.service';
import { RegistrationNode } from '@app/registration/graph/nodes/registration.node';

@Injectable({
  providedIn: 'root',
})
export class MfaNode extends RegistrationNode {
  constructor(
    mfaToMarkRegCompleteEdge: MfaToMarkRegCompleteEdge,
    nodeUrlMappingService: NodeUrlMappingService,
    router: Router,
  ) {
    super(nodeUrlMappingService, router);
    this.edges = [mfaToMarkRegCompleteEdge];
  }
}
