import { Component, OnInit } from '@angular/core';

import { GraphNavigationService } from '@app/registration/graph/graph-navigation.service';
import { NavbarHeaderComponent } from '@app/shared/navbar-header/navbar-header.component';
import { SharedModule } from '@app/shared/shared.module';

@Component({
  selector: 'om-mfa',
  templateUrl: './mfa.component.html',
  standalone: true,
  imports: [NavbarHeaderComponent, SharedModule],
})
export class MfaComponent implements OnInit {
  protected percentComplete: number | null = null;

  constructor(private graphNavigationService: GraphNavigationService) {}

  ngOnInit(): void {
    if (this.graphNavigationService.navigationInProgress) {
      this.percentComplete = this.graphNavigationService.percentComplete;
    }
  }

  onSubmit() {
    this.graphNavigationService.navigate();
  }
}
