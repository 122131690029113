import { Injectable } from '@angular/core';

import { AnalyticsService } from '@app/core/analytics.service';
import { FLOW_ACCOUNT_UPDATE, MODULE_SETTINGS_PAGE } from '@app/core/mixpanel.constants';

import { Coupon } from '../coupon';

@Injectable()
export class PaymentModalAnalyticsService extends AnalyticsService {
  private paymentModalFlowAndModule = {
    flow: FLOW_ACCOUNT_UPDATE,
    module: 'Convert to Personal Membership',
    submodule: <string>null,
  };

  trackPaymentSuccess(membershipType: string, flowVersion?: string) {
    return this.trackWithDefaultProperties('Billing Info Submitted', {
      ...this.paymentModalFlowAndModule,
      om_membership_type: membershipType,
      is_whitelist: false,
      ...(flowVersion && { flow_version: flowVersion }),
    });
  }

  trackDiscountSuccess(membershipType: string, coupon: Coupon) {
    return this.trackWithDefaultProperties('Discount Code Applied', {
      ...this.paymentModalFlowAndModule,
      om_membership_type: membershipType,
      promotion_code: coupon.id,
      promotion_code_amount: this.formatCouponAmountOff(coupon.amountOff),
      promotion_code_percent: coupon.percentOff,
    });
  }

  trackConvertTrialMembershipDiscountSuccess(coupon: Coupon) {
    return this.trackWithDefaultProperties('Trial to Consumer Discount Code Applied', {
      ...this.paymentModalFlowAndModule,
      module: MODULE_SETTINGS_PAGE,
      promotion_code: coupon.id,
      promotion_code_amount: this.formatCouponAmountOff(coupon.amountOff),
      promotion_code_percent: coupon.percentOff,
    });
  }

  trackDiscountFailure(membershipType: string) {
    return this.trackWithDefaultProperties('Discount Code Failed', {
      ...this.paymentModalFlowAndModule,
      om_membership_type: membershipType,
    });
  }

  trackConvertTrialMembershipCompleted() {
    return this.trackWithDefaultProperties('Trial to Consumer Conversion Completed', {
      ...this.paymentModalFlowAndModule,
      module: MODULE_SETTINGS_PAGE,
    });
  }

  private formatCouponAmountOff(amountOff: number): number | null {
    return amountOff ? Math.floor(amountOff / 100) : null;
  }
}
