import { Injectable } from '@angular/core';
import { captureMessage, captureException, SeverityLevel, addBreadcrumb } from '@sentry/angular-ivy';

export * from './sentry-metric.constants';
/**
 * Options for logging success messages.
 *
 * @property {string} operationName - A unique identifier for the operation.
 * @property {string} message - The message to be logged.
 * @property {SeverityLevel} [level='info'] - The severity level of the log. Default is 'info'.
 *   - 'fatal': Logs only fatal errors.
 *   - 'error': Logs errors and fatal errors.
 *   - 'warning': Logs warnings, errors, and fatal errors.
 *   - 'log': Logs informational messages, warnings, errors, and fatal errors.
 *   - 'info': Logs informational messages, warnings, errors, and fatal errors (same as 'log').
 *   - 'debug': Logs all messages, including debug messages.
 */
interface SuccessLogOptions {
  operationName: string;
  message: string;
  level?: SeverityLevel;
}

/**
 * Options for logging error messages.
 *
 * @property {string} operationName - A unique identifier for the operation.
 */
interface ErrorLogOptions {
  operationName: string;
}

@Injectable({
  providedIn: 'root',
})
export class SentryMetricService {
  breadcrumb = addBreadcrumb;

  log({ operationName, message, level = 'info' }: SuccessLogOptions) {
    captureMessage(message, {
      tags: { operationName },
      contexts: {
        operation: { status: 'success' },
      },
      level,
    });
  }

  error({ operationName }: ErrorLogOptions, error: any) {
    captureException(error, {
      tags: { operationName },
      contexts: {
        operation: { status: 'error' },
      },
    });
  }
}
