import snakeCase from 'lodash-es/snakeCase';

import { AccordionItem } from '@app/shared/accordion/accordion.component';

export const icons = {
  adult: '/assets/images/sign-up-myself.svg',
  child: '/assets/images/sign-up-child.svg',
  family: '/assets/images/sign-up-me-and-child.svg',
  suitcase: '/assets/images/suitcase-icon.svg',
};

export const FAMILY_TYPE_FAQ_ITEMS: AccordionItem[] = [
  {
    title: 'What insurance plans does One Medical accept?',
    content: `One Medical accepts health plans from most insurance carriers, as well as Medicare. Some of the more popular plans that we accept are listed on our <a target="_blank" href="https://www.onemedical.com/insurance/">Insurance page</a>.`,
  },

  {
    title: 'Why is there an annual membership fee?',
    content: `
      The One Medical annual membership fee covers access to One Medical’s on-demand and asynchronous virtual care services as well as high-touch and value-added personal services such as insurance navigation and referral management. The on-demand virtual services are accessible through the One Medical app (at no additional cost), featuring 24/7/365 on-demand video chats, secure provider messaging, “Treat Me Now” assessments for common health concerns, easy vaccine and medical record access, prescription renewals, and proactive reminders for follow-up care and referral needs.<br><br>
      The Annual Membership Fee is not a covered benefit under most health insurance plans.<br><br>
      Payment of the Annual Membership Fee is not a prerequisite for receiving medical care in One Medical offices. To understand your options for accessing medical services with One Medical without payment of the Annual Membership Fee, <a target="_blank" href="https://www.onemedical.com/faq/membership-fee-alternatives/">click here</a>.
    `,
  },
  {
    title: 'How does One Medical differ from other primary care and urgent care practices?',
    content: `
      One Medical aims to make health care easier to access and even enjoyable, while improving health outcomes for people across every stage of life. One Medical offers same and next-day in-office or remote visits, seamlessly paired with 24/7 on-demand virtual care services through the One Medical mobile or web app, allowing members to seek care when and where it’s most convenient to them. One Medical is designed to better serve the needs of its patients and providers, including through thoughtfully designed and welcoming offices across the U.S., appointments that start on-time, more appointment time with providers, and onsite labs. One Medical provides a comprehensive and human-centered primary care experience, including preventive and everyday health visits, chronic care management, pediatric and mental health services in a growing number of locations, and <a target="_blank" href="https://www.onemedical.com/services/">more</a>. Members enjoy access to 24/7/365 virtual care services through the One Medical app, which allows them to continue their care from the comfort of home or on the go, whether it’s for acute needs at odd-hours, or to simply manage follow-up needs and prescription renewals.
    `,
  },
  {
    title: `What’s the relationship between Amazon and One Medical?`,
    content: `
      1Life Healthcare, Inc. (“1Life”) is now a subsidiary of Amazon. 1Life is the administrative and managerial services company for the affiliated One Medical physician-owned professional corporations that deliver medical services. 1Life and the One Medical professional corporations do business under the "One Medical" brand. The Amazon transaction does not change the ownership of the One Medical professional corporations.  One Medical members can continue to count on One Medical for the high-quality care and high-level of service they receive.
    `,
  },
  {
    title: 'How do Amazon and One Medical protect private health information?',
    content: `
      The Health Insurance Portability and Accountability Act (HIPAA) governs what One Medical, Amazon, and others can do with Protected Health Information and this includes information like medication history, medical conditions, and treatment information. Amazon and One Medical have extensive experience protecting data of all kinds appropriately across a variety of businesses and nothing about this acquisition changes Amazon or One Medical’s commitment to privacy or the strong protections we have for Protected Health Information.
    `,
  },
  {
    title: 'Where is One Medical located? Can I visit One Medical offices in different cities?',
    content: `
      One Medical members are welcome to book an appointment at any of our many thoughtfully designed and <a target="_blank" href="http://onemedical.com/locations">welcoming offices across the U.S.</a> For your convenience, we recommend checking with our office prior to making an appointment to ensure your insurance plan is accepted at the office you plan to visit, as this may vary based on location.
    `,
  },
  {
    title: 'I’m 65+ years and on Medicare. Does One Medical have an oﬀering for me?',
    content: `
      One Medical provides comprehensive primary care for seniors on Medicare.<br><br>
      <a target="_blank" href="https://www.onemedical.com/sixty-five-plus/become-a-patient/">Learn more about One Medical's primary care for seniors</a>
    `,
  },
  {
    title: 'I still have questions. Where can I learn more?',
    content: `
      We're here to help. For more information, check out <a class="mp-tracking-link" target="_blank" href="https://www.onemedical.com/faq">additional frequently asked questions.</a>
    `,
  },
].map((faqItem: AccordionItem) => {
  faqItem.id = snakeCase(faqItem.title);
  return faqItem;
});

export const registrationFlowStepDetails: Record<string, RegistrationFlowStepCount> = {
  loginWithAmazon: {
    totalSteps: 4,
    startingStep: 1,
  },
  chartClaiming: {
    totalSteps: 4,
    startingStep: 2,
  },
};

export const amazonFlowStatus = {
  hornbill: 'hornbill',
  shelduck_omee: 'shelduck_omee',
  login_with_amazon: 'login_with_amazon',
  gcn_trial: 'gcn_trial',
};

export interface RegistrationFlowStepCount {
  totalSteps: number;
  startingStep: number;
}
