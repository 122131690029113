import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Params, UrlTree } from '@angular/router';
import { Observable, of as observableOf } from 'rxjs';
import { map } from 'rxjs/operators';

import { AttemptedPathService } from '@app/core/attempted-path.service';

import { AuthService } from './auth.service';
import { SocialConnection } from './auth0-client.service';

export const LAUNCH_LOGIN_WITH_AMAZON = 'lwa';
export const RETURN_URL = 'returnUrl';

@Injectable()
export class LoginGuardService implements CanActivate {
  constructor(private attemptedPathService: AttemptedPathService, private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const queryParams = route.queryParams;
    if (route.queryParams[LAUNCH_LOGIN_WITH_AMAZON] === 'true') {
      this.launchLoginWithAmazon(queryParams);
      return observableOf(false);
    }

    return this.authService.isLoggedIn$().pipe(
      map(isLoggedIn => {
        this.setAttemptedPathFromParams(queryParams);

        if (isLoggedIn) {
          this.attemptedPathService.navigateToAttemptedPath();
          return false;
        }

        this.authService.goLogin();
        return false;
      }),
    );
  }

  setAttemptedPathFromParams(params: Params) {
    const path = decodeURIComponent(params[RETURN_URL] || '');
    if (path && path.length > 1) {
      this.attemptedPathService.setAttemptedPath(path);
    }
  }

  private launchLoginWithAmazon(params: Params): void {
    const decodedReturnUrl = decodeURIComponent(params[RETURN_URL] || '');

    this.authService.goLogin({
      ...(decodedReturnUrl.length > 1 ? { path: decodedReturnUrl } : {}),
      customAuthorizationParams: {
        connection: SocialConnection.Amazon,
      },
    });
  }
}
