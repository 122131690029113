import { Injectable } from '@angular/core';
import { Mutation } from 'apollo-angular';
import gql from 'graphql-tag';

import {
  UpdateStripePaymentMethod,
  UpdateStripePaymentMethodVariables,
} from './__generated__/UpdateStripePaymentMethod';

@Injectable({
  providedIn: 'root',
})
export class UpdateStripePaymentMethodGraphQL extends Mutation<
  UpdateStripePaymentMethod,
  UpdateStripePaymentMethodVariables
> {
  document = gql`
    mutation UpdateStripePaymentMethod($stripePaymentMethodId: String!, $nickname: String) {
      updateStripePaymentMethodForPatient(
        input: { stripePaymentMethodId: $stripePaymentMethodId, nickname: $nickname }
      ) {
        success
        errors
      }
    }
  `;
}
