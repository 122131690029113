import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';

import { OnboardingAnalyticsService } from '@app/onboarding/onboarding-analytics.service';
import * as OnboardingPromptActions from '@app/onboarding/onboarding.actions';

@Injectable()
export class OnboardingPromptsAnalyticsEffects {
  constructor(private actions$: Actions, private onboardingAnalyticsService: OnboardingAnalyticsService) {}

  trackOnboardingPromptClicked$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OnboardingPromptActions.selectOnboardingPrompt),
        tap(action => {
          this.onboardingAnalyticsService.onboardingPromptClicked({
            answer_type: action.onboarding_prompt_selected,
          });
        }),
      ),
    { dispatch: false },
  );

  trackOnboardingPrimaryActionClicked$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OnboardingPromptActions.clickOnboardingPromptAction),
        tap(action => {
          this.onboardingAnalyticsService.onboardingActionClicked(action.action_selected, { flow: action.flow });
        }),
      ),
    { dispatch: false },
  );

  trackOnboardingSecondaryActionClicked$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OnboardingPromptActions.dismissOnboardingAction),
        tap(_action => {
          this.onboardingAnalyticsService.onboardingSecondaryActionClicked();
        }),
      ),
    { dispatch: false },
  );
}
