<om-navbar-header
  [canGoBack]="false"
  [showLoginLink]="false"
  [showProgressBar]="!!percentComplete"
  [percentComplete]="percentComplete"
>
</om-navbar-header>
<om-flash></om-flash>

<div class="mt-xl-10 mt-2">
  <mfa-reg-step (submit)="onSubmit()"></mfa-reg-step>
</div>
