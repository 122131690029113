import { Injectable } from '@angular/core';
import { gql, Query } from 'apollo-angular';

import {
  HasClaimedAccessKeyGraphqlServiceResult,
  HasClaimedAccessKeyGraphqlServiceVariables,
} from '@app/core/__generated__/has-claimed-access-key-graphql.service.types';

@Injectable({
  providedIn: 'root',
})
export class HasClaimedAccessKeyGraphqlService extends Query<
  HasClaimedAccessKeyGraphqlServiceResult,
  HasClaimedAccessKeyGraphqlServiceVariables
> {
  document = gql`
    query HasClaimedAccessKey {
      patient {
        id
        hasClaimedAccessKey
      }
    }
  `;
}
