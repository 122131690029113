<div>
  <ng-container *ngIf="!(hideNavigation$ | async)">
    <om-navbar-container data-cy="navbar"></om-navbar-container>
  </ng-container>
  <om-expired-membership-banner></om-expired-membership-banner>
  <om-flash></om-flash>
  <om-toast></om-toast>
</div>

<div class="flex-grow-1">
  <ng-container *rxLet="initialized$; let initialized">
    <router-outlet *ngIf="initialized"></router-outlet>
  </ng-container>
</div>

<om-tos-update-container></om-tos-update-container>

<om-footer *ngIf="!(hideFooter$ | async)" [homePage]="onHomePage" data-cy="footer"></om-footer>
