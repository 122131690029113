import { Injectable } from '@angular/core';
import { gql, Query } from 'apollo-angular';

import { PatientUIStartToMfaEdge } from '@app/registration/graph/edges/__generated__/start-to-mfa-edge-graphql.service.types';

@Injectable({
  providedIn: 'root',
})
export class StartToMfaEdgeGraphqlService extends Query<PatientUIStartToMfaEdge> {
  document = gql`
    query PatientUIStartToMfaEdge {
      patient {
        id
        multiFactorAuthentication {
          enabled
        }
        hasClaimedAccessKey
      }
    }
  `;
}
