import { Component, Input } from '@angular/core';
import { map } from 'rxjs/operators';

import { FeatureFlags } from '@app/core/feature-flags/feature-flags';
import { LaunchDarklyService } from '@app/core/feature-flags/launchdarkly.service';
import { Office } from '@app/shared/office';
import { Provider } from '@app/shared/provider';
import { OmguiButtonSize, OmguiButtonType, OmguiButtonVariant } from '@omgui/omgui-button/omgui-button.component';

export enum BiosInBookingExperiment {
  Off = 'OFF',
  Control = 'ON CONTROL',
  WithSummary = 'ON WITH SUMMARY',
}

@Component({
  selector: 'om-provider-detail',
  templateUrl: './provider-detail.component.html',
  styleUrls: ['./provider-detail.component.scss'],
})
export class ProviderDetailComponent {
  @Input() provider: Provider;
  @Input() office: Office;
  @Input() remote: boolean;

  get officeGoogleMapsLink(): string {
    const address = this.office.address;
    const { city, state, zip } = address;
    const entireAddress = `One Medical ${address.fullAddress}, ${city}, ${state}, ${zip}`;
    return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(entireAddress)}`;
  }

  protected readonly ButtonVariant = OmguiButtonVariant;
  protected readonly OmguiButtonType = OmguiButtonType;
  protected readonly OmguiButtonSize = OmguiButtonSize;
  protected readonly displayProviderBios$ = this.launchDarklyService
    .featureFlag$(FeatureFlags.BHX_PROVIDER_BIOS_IN_BOOKING_EXPERIMENT, BiosInBookingExperiment.Off)
    .pipe(map(variant => BiosInBookingExperiment.WithSummary === variant));

  constructor(private launchDarklyService: LaunchDarklyService) {}
}
