import { Injectable } from '@angular/core';
import { Observable, of as observableOf, tap } from 'rxjs';

import { SentryMetricService, BreadcrumbCategory } from '@app/core/metrics/sentry-metric.service';
import { RegistrationEdge } from '@app/registration/graph/graph-navigation.service';

import { GraphNavigationStorageService } from '../graph-navigation-storage.service';
import { BookAppointmentNode } from '../nodes/book-appointment.node';

export enum PostRegistrationAction {
  BOOKING = 'booking',
}
@Injectable({
  providedIn: 'root',
})
export class MembershipUpdateToBookAppointmentEdge implements RegistrationEdge {
  nextNode = BookAppointmentNode;

  private readonly METRIC_NAME_PREFIX = 'membership_update_to_book_appointment_edge';

  constructor(
    private graphNavigationStorageService: GraphNavigationStorageService,
    private sentryMetricService: SentryMetricService,
  ) {}

  private hasBookingParam(): boolean {
    const graphNavigationArgs = this.graphNavigationStorageService.fetchGraphNavigationArgs();

    return graphNavigationArgs?.postRegistrationAction === PostRegistrationAction.BOOKING;
  }

  private logShouldNavigate(hasBookingParam: boolean) {
    this.sentryMetricService.breadcrumb({
      message: `${this.METRIC_NAME_PREFIX}_should_navigate`,
      category: BreadcrumbCategory.GraphNavigation,
      data: { hasBookingParam },
    });
  }

  shouldNavigate$(): Observable<boolean> {
    return observableOf(this.hasBookingParam()).pipe(tap(hasBookingParam => this.logShouldNavigate(hasBookingParam)));
  }
}
