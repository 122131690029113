<div class="d-flex flex-grow-1">
  <div class="d-flex flex-column mx-1 mt-3">
    <div class="provider-detail">
      <h4 class="align-self-baseline fw-bold m-0">{{ provider.displayName }}</h4>
      <p class="fw-bold specialty-text d-none d-sm-block"></p>
    </div>
    <div class="d-flex flex-column flex-grow-1 justify-content-start">
      <div class="office-detail">
        <p class="office-name m-0 d-none d-sm-block" data-cy="office-name">{{ office.name }}</p>
        <omgui-button
          class="d-sm-none"
          [variant]="ButtonVariant.link"
          [size]="OmguiButtonSize.small"
          [externalLink]="officeGoogleMapsLink"
          [newTab]="true"
          [type]="OmguiButtonType.button"
          [customButtonClass]="'fw-normal px-0 pt-0 border-0'"
          data-cy="office-name-map-link"
        >
          {{ office.name }}
        </omgui-button>
        <p class="remote-office-address fw-light" data-cy="remote-message" *ngIf="remote; else officeAddress">
          This Remote Visit will take place over Zoom
        </p>
      </div>
      <ng-container *ngIf="displayProviderBios$ | async">
        <om-provider-bio-summary [providerId]="provider.id" [office]="office"></om-provider-bio-summary>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #officeAddress>
  <p class="office-address fw-light d-none d-sm-block" data-cy="office-address">
    {{ office.address.fullAddress }}<br />
    {{ office.address.city }}, {{ office.address.state }} {{ office.address.zip }}
  </p>
</ng-template>
