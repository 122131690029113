import { Injectable } from '@angular/core';
import { Query, gql } from 'apollo-angular';

import { PatientPaymentMethods } from './__generated__/payment-methods-graphql.service.types';

@Injectable({
  providedIn: 'root',
})
export class PaymentMethodsGraphQL extends Query<PatientPaymentMethods> {
  document = gql`
    query PatientPaymentMethods {
      patient {
        id
        paymentMethods {
          ...paymentMethod
        }
      }
    }
    ${paymentMethod}
  `;
}

const paymentMethod = gql`
  fragment paymentMethod on PaymentMethod {
    id
    last4
    expMonth
    expYear
    brand
    billingName
    cardNickname
    isDefaultCopay
  }
`;
