import { Injectable } from '@angular/core';
import { Mutation } from 'apollo-angular';
import gql from 'graphql-tag';

import {
  DetachStripePaymentMethod,
  DetachStripePaymentMethodVariables,
} from './__generated__/DetachStripePaymentMethod';

@Injectable({
  providedIn: 'root',
})
export class DetachStripePaymentMethodGraphQL extends Mutation<
  DetachStripePaymentMethod,
  DetachStripePaymentMethodVariables
> {
  document = gql`
    mutation DetachStripePaymentMethod($stripePaymentMethodId: String!) {
      detachStripePaymentMethodForPatient(input: { stripePaymentMethodId: $stripePaymentMethodId }) {
        success
        errors
      }
    }
  `;
}
