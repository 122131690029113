import { Injectable } from '@angular/core';
import { Mutation } from 'apollo-angular';
import gql from 'graphql-tag';

import { CreateStripeSetupIntentForPatient } from './__generated__/CreateStripeSetupIntentForPatient';

@Injectable({
  providedIn: 'root',
})
export class CreateStripeSetupIntentGraphQL extends Mutation<CreateStripeSetupIntentForPatient, null> {
  document = gql`
    mutation CreateStripeSetupIntentForPatient {
      createStripeSetupIntentForPatient(input: {}) {
        setupIntentId
        setupIntentClientSecret
        success
        errors
      }
    }
  `;
}
