import { Injectable } from '@angular/core';
import { Mutation, gql } from 'apollo-angular';

import {
  PatientUpdateDefaultCopayStripeId,
  PatientUpdateDefaultCopayStripeIdVariables,
} from './__generated__/update-default-copay-stripe-id-graphql.service.types';

@Injectable({
  providedIn: 'root',
})
export class UpdateDefaultCopayStripeIdGraphQL extends Mutation<
  PatientUpdateDefaultCopayStripeId,
  PatientUpdateDefaultCopayStripeIdVariables
> {
  document = gql`
    mutation PatientUpdateDefaultCopayStripeId($stripePaymentMethodId: String!) {
      updateDefaultCopayStripeIdForPatient(input: { stripePaymentMethodId: $stripePaymentMethodId }) {
        success
        mutationErrors {
          messages
          path
        }
      }
    }
  `;
}
