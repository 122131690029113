export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  HTML: string;
  JSON: any;
  Markdown: string;
  TZInfo: any;
  Upload: any;
}

/** Autogenerated input type of AcceptFamilyAccountInvitation */
export interface AcceptFamilyAccountInvitationInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
}

/** Autogenerated input type of AddInsuranceWithVerification */
export interface AddInsuranceWithVerificationInput {
  carrierName?: InputMaybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  groupNumber?: InputMaybe<Scalars['String']>;
  memberId: Scalars['String'];
}

export interface AddressAttributes {
  _destroy?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  address1?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  crossStreet?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  kind?: InputMaybe<AddressKind>;
  stateCode?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
}

export enum AddressKind {
  Billing = 'billing',
  Home = 'home',
  Other = 'other',
  Work = 'work',
}

export enum AddressStatus {
  Current = 'CURRENT',
  Previous = 'PREVIOUS',
}

export enum AmazonPharmacyBrand {
  AmazonPharmacy = 'AmazonPharmacy',
  Other = 'Other',
}

/** various queryable relationships between patient and appointment */
export enum AppointmentQueryStrategy {
  ConnectedDevice = 'CONNECTED_DEVICE',
  /** For an appointment to be current, it must not have the state `no_show` or `cancelled`. The `end_at` must be in the future. It must _either_ have the `start_at` in the past _or_ be in the state `arrived` or `started`. */
  Current = 'CURRENT',
  /** Only includes appointments with state 'no show' or 'cancelled' */
  NotUtilized = 'NOT_UTILIZED',
  Past = 'PAST',
  /** appointments with a state of seen */
  Seen = 'SEEN',
  Upcoming = 'UPCOMING',
}

export interface AppointmentTypeInput {
  id?: InputMaybe<Scalars['ID']>;
}

/** Autogenerated input type of AssignMembershipGroupSeat */
export interface AssignMembershipGroupSeatInput {
  claimCode: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  lwaPurchaseRef?: InputMaybe<Scalars['String']>;
}

/** Autogenerated input type of AttestSelfPayIsPrimaryInsurance */
export interface AttestSelfPayIsPrimaryInsuranceInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
}

/** Autogenerated input type of AuthorizeAmazonPharmacySharing */
export interface AuthorizeAmazonPharmacySharingInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
}

/** Autogenerated input type of AutoEnrollMfa */
export interface AutoEnrollMfaInput {
  /** The preferred factor to use for MFA. Valid options are sms or voice */
  authFactor?: InputMaybe<MfaFactor>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Email to enroll in MFA as a backup factor */
  email?: InputMaybe<Scalars['String']>;
  /** Email address of pediatric user to have MFA enabled by an authorized parent */
  pediatricEmail?: InputMaybe<Scalars['String']>;
  /** Phone number to enroll in MFA. Format should be American 10-digit standard, following XXX-XXX-XXXX */
  phoneNumber: Scalars['String'];
}

export interface BookAppointmentAttributes {
  appointmentTypeId: Scalars['ID'];
  /** ID of the appointment inventory that will be booked as a part of rescheduling. */
  inventoryId: Scalars['ID'];
  reason?: InputMaybe<Scalars['String']>;
  sendSms?: InputMaybe<Scalars['Boolean']>;
  smsNumber?: InputMaybe<Scalars['String']>;
}

/** Autogenerated input type of BookAppointment */
export interface BookAppointmentInput {
  appointmentAttributes: BookAppointmentAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
}

/** Autogenerated input type of CancelAppointment */
export interface CancelAppointmentInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
}

/** Autogenerated input type of CancelPrescriptionRenewalRequests */
export interface CancelPrescriptionRenewalRequestsInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  patientMedicationIds: Array<Scalars['ID']>;
}

export enum CareTeamType {
  /** PCP + mindset providers with domain specific labels (e.g. Therapist, Care Manager, Unknown) instead of default program enrollment label */
  BehavioralHealth = 'BEHAVIORAL_HEALTH',
  /** Everything in LONGITUDINAL plus appointments */
  Comprehensive = 'COMPREHENSIVE',
  /** PCP, then any program-based care */
  Longitudinal = 'LONGITUDINAL',
}

/** Autogenerated input type of ChangePharmacyForPrescriptionRenewalRequests */
export interface ChangePharmacyForPrescriptionRenewalRequestsInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  patientMedicationIds: Array<Scalars['ID']>;
  patientPharmacyId: Scalars['ID'];
}

export interface ChartClaimingPatientAttributes {
  dateOfBirth?: InputMaybe<Scalars['Date']>;
  legalFirstName?: InputMaybe<Scalars['String']>;
  legalLastName?: InputMaybe<Scalars['String']>;
}

/** Autogenerated input type of ClaimChart */
export interface ClaimChartInput {
  accessKey: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  password: Scalars['String'];
  patientAttributes: ChartClaimingPatientAttributes;
  reCaptchaToken?: InputMaybe<Scalars['String']>;
}

/** Autogenerated input type of ConfirmAppointment */
export interface ConfirmAppointmentInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
}

export enum ContentBlockMetadataStyles {
  Dark = 'Dark',
  ErrorLight = 'ErrorLight',
  InfoLight = 'InfoLight',
  Light = 'Light',
  SuccessLight = 'SuccessLight',
  WarningLight = 'WarningLight',
}

/** Autogenerated input type of CreateCustomPharmacy */
export interface CreateCustomPharmacyInput {
  addressAttributes: AddressAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Applies to mail order pharmacies only. Not required, even for mail order pharmacies. */
  customerCode?: InputMaybe<Scalars['String']>;
  isMailOrder?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  phoneNumbersAttributes: PhoneNumberAttributes;
}

/** Autogenerated input type of CreateFamilyAccountInvitation */
export interface CreateFamilyAccountInvitationInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  fullName: Scalars['String'];
}

/** Autogenerated input type of CreateHealthInformationReleaseAuthorization */
export interface CreateHealthInformationReleaseAuthorizationInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  documentVersion: Scalars['String'];
  expiresAt?: InputMaybe<Scalars['DateTime']>;
  recipientTelephone: Scalars['String'];
  signatureText: Scalars['String'];
  signedAt: Scalars['DateTime'];
}

/** Autogenerated input type of CreateHealthKitMeasurements */
export interface CreateHealthKitMeasurementsInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  measurementType?: InputMaybe<Scalars['String']>;
  measurements: Array<HealthKitDeviceMeasurementInputObject>;
}

/** Autogenerated input type of CreatePatientPharmacy */
export interface CreatePatientPharmacyInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Applies to mail-order pharmacies only. Not required, even for mail order pharmacies. */
  customerCode?: InputMaybe<Scalars['ID']>;
  pharmacyId: Scalars['ID'];
}

/** Autogenerated input type of CreatePatientSurvey */
export interface CreatePatientSurveyInput {
  answers: Array<PatientSurveyAnswer>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  confirmed: Scalars['Boolean'];
  /** Deprecated, do not use. To be removed. */
  surveyId?: InputMaybe<Scalars['ID']>;
  surveySystemName?: InputMaybe<Scalars['String']>;
  taskId?: InputMaybe<Scalars['ID']>;
}

/** Autogenerated input type of CreatePediatricMembershipGroupInvite */
export interface CreatePediatricMembershipGroupInviteInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
}

export interface CreatePrescriptionRenewalRequestAttributes {
  comment?: InputMaybe<Scalars['String']>;
  patientMedicationId: Scalars['ID'];
}

/** Autogenerated input type of CreatePrescriptionRenewalRequests */
export interface CreatePrescriptionRenewalRequestsInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  createPrescriptionRenewalRequestAttributes: Array<CreatePrescriptionRenewalRequestAttributes>;
  patientPharmacyId: Scalars['ID'];
}

/** Autogenerated input type of CreateStripeSetupIntentForPatient */
export interface CreateStripeSetupIntentForPatientInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
}

/** Autogenerated input type of CreateToken */
export interface CreateTokenInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  grantType?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  username: Scalars['String'];
}

/** Autogenerated input type of DeclineFamilyAccountInvitation */
export interface DeclineFamilyAccountInvitationInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
}

/** Autogenerated input type of DeleteTimelineCommentDraft */
export interface DeleteTimelineCommentDraftInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Patient Timeline Comment ID */
  id: Scalars['ID'];
}

/** Autogenerated input type of DeleteTimelinePostDraft */
export interface DeleteTimelinePostDraftInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Patient Timeline Post ID */
  id: Scalars['ID'];
}

/** Autogenerated input type of DemographicsToken */
export interface DemographicsTokenInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
}

/** Autogenerated input type of DetachStripePaymentMethodForPatient */
export interface DetachStripePaymentMethodForPatientInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  stripePaymentMethodId: Scalars['String'];
}

/** Enum for Home Screen dialog types */
export enum DialogType {
  ModifyVisit = 'modifyVisit',
  MoreOptions = 'moreOptions',
}

/** Autogenerated input type of DisableAccountSwitching */
export interface DisableAccountSwitchingInput {
  accessRelationshipId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
}

/** Autogenerated input type of DismissAlert */
export interface DismissAlertInput {
  alertKey: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
}

/** Autogenerated input type of DismissWebDownloadMobileAppHero */
export interface DismissWebDownloadMobileAppHeroInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
}

export enum ElectronicHealthInformationExportFormat {
  Pdf = 'PDF',
  Zip = 'ZIP',
}

export enum ElectronicHealthInformationExportState {
  Available = 'available',
  Failed = 'failed',
  InProgress = 'in_progress',
  Requested = 'requested',
  Retrying = 'retrying',
}

export enum EmailKind {
  B2bEmployeeWork = 'b2b_employee_work',
  DirectMessage = 'direct_message',
  Other = 'other',
  Personal = 'personal',
  Shared = 'shared',
  Work = 'work',
}

export enum EmailVerificationStatus {
  LockedOut = 'locked_out',
  Unverified = 'unverified',
  Verified = 'verified',
}

export interface EmergencyContactInputObject {
  _destroy?: InputMaybe<Scalars['Boolean']>;
  addressAttributes?: InputMaybe<PatientAddressInputObject>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  isPreferred?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumberAttributes?: InputMaybe<PatientPhoneNumberInputObject>;
  relationship?: InputMaybe<Scalars['String']>;
}

/** Autogenerated input type of EnableAccountSwitching */
export interface EnableAccountSwitchingInput {
  accessRelationshipId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
}

export enum ErrorTypes {
  NonRecoverable = 'NON_RECOVERABLE',
  RetryableImmediate = 'RETRYABLE_IMMEDIATE',
}

/** Autogenerated input type of ExpireUserNotification */
export interface ExpireUserNotificationInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
}

export enum FlagVariant {
  Off = 'Off',
  OnAlternateText = 'OnAlternateText',
  OnControlText = 'OnControlText',
}

/** Autogenerated input type of ForgetAmazonPharmacyEnrollment */
export interface ForgetAmazonPharmacyEnrollmentInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
}

export enum FreeTextQuestionId {
  UserFreetextFeedback = 'USER_FREETEXT_FEEDBACK',
}

export interface FreetextResponseInput {
  /** Must be "USER_FREETEXT_FEEDBACK" */
  questionId: FreeTextQuestionId;
  /** The text of the question */
  questionText: Scalars['String'];
  /** Typed response from the user for this question */
  response: Scalars['String'];
}

/** Autogenerated input type of GenerateElectronicHealthInformationExport */
export interface GenerateElectronicHealthInformationExportInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  format: ElectronicHealthInformationExportFormat;
}

export interface GeneratedResponsePayloadInput {
  content?: InputMaybe<Scalars['String']>;
  requiresGeneratedInput: Scalars['Boolean'];
  type: Scalars['String'];
}

/** Strongly-typed lat/long input. */
export interface Geolocation {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
}

export interface HealthKitDeviceAttributes {
  manufacturer?: InputMaybe<Scalars['String']>;
  modelNumber?: InputMaybe<Scalars['String']>;
  udiCarrierAttributes?: InputMaybe<Array<UdiCarrierAttributes>>;
  userFriendlyName?: InputMaybe<Scalars['String']>;
}

export interface HealthKitDeviceMeasurementInputObject {
  /** Format should be ISO 8601 */
  collectedAt?: InputMaybe<Scalars['DateTime']>;
  deviceAttributes?: InputMaybe<HealthKitDeviceAttributes>;
  name: MeasurementName;
  sourceAttributes?: InputMaybe<HealthKitSourceAttributes>;
  uuid: Scalars['String'];
  value: Scalars['String'];
}

export interface HealthKitSourceAttributes {
  bundleIdentifier?: InputMaybe<Scalars['String']>;
  /** The name of the device or healthkit linked app the data is coming from */
  name?: InputMaybe<Scalars['String']>;
  /** The operating system version of the device sending the data */
  operatingSystemVersion?: InputMaybe<Scalars['String']>;
  productType?: InputMaybe<Scalars['String']>;
  /** The version of the app or device sending the data */
  version?: InputMaybe<Scalars['String']>;
}

export interface InternalUserDisplayNameOptions {
  lastInitial?: InputMaybe<Scalars['Boolean']>;
  showSuffix?: InputMaybe<Scalars['Boolean']>;
}

/** Autogenerated input type of LinkAmazonAccount */
export interface LinkAmazonAccountInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
}

export enum LinkingInitiatedEvent {
  LinkingInitiated = 'linking_initiated',
  LinkingInitiatedWithDataSharing = 'linking_initiated_with_data_sharing',
}

/** Autogenerated input type of LinkingInitiated */
export interface LinkingInitiatedInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  eventType: LinkingInitiatedEvent;
}

/** Autogenerated input type of MarkAllUserNotificationsAsRead */
export interface MarkAllUserNotificationsAsReadInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** For the given Patient::Timeline::Post#id, marks all notifications for that post and its comments as read. If omitted, all notifications for the user are marked as read. */
  postId?: InputMaybe<Scalars['ID']>;
}

/** Autogenerated input type of MarkAsLateToAppointment */
export interface MarkAsLateToAppointmentInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
}

/** Autogenerated input type of MarkRegComplete */
export interface MarkRegCompleteInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
}

export enum MeasurementName {
  DiastolicPressure = 'diastolicPressure',
  HeartRate = 'heartRate',
  SystolicPressure = 'systolicPressure',
  Weight = 'weight',
}

export enum MembershipGroupInviteStatus {
  Claimed = 'claimed',
  Created = 'created',
  FailedToSend = 'failed_to_send',
  InProgress = 'in_progress',
  Rejected = 'rejected',
  Revoked = 'revoked',
  Sent = 'sent',
}

export enum MembershipGroupSeatStatus {
  Active = 'active',
  Cancelled = 'cancelled',
  Open = 'open',
  Pending = 'pending',
}

export enum MembershipGroupSeatStoppedBy {
  External = 'external',
  OmMember = 'om_member',
}

export enum MfaFactor {
  Sms = 'sms',
  Voice = 'voice',
}

export interface MultipleChoicePayloadInput {
  /** An array containing multiple choice questions with their selection indices populated */
  content: Array<MultipleChoiceQuestionInput>;
  /** Set to true as returned by toucanNewConversation or toucanContinueConversation */
  requiresMultipleChoiceSelectionInput: Scalars['Boolean'];
  /** must be "multipleChoice". */
  type: Scalars['String'];
}

export enum MultipleChoiceQuestionConstraint {
  MultipleSelection = 'MULTIPLE_SELECTION',
  SingleSelection = 'SINGLE_SELECTION',
}

export interface MultipleChoiceQuestionInput {
  /** Determines whether this question allows multiple options or just a single option to be selected */
  constraint: MultipleChoiceQuestionConstraint;
  /** An array containing answer texts as options for the user */
  optionsList: Array<Scalars['String']>;
  /** The multiple choice question text as returned by toucanNewConversation or toucanContinueConversation */
  question: Scalars['String'];
  /** Populate this array with the indices of the options selected by the user */
  selectionIndices: Array<Scalars['Int']>;
}

export interface MultipleChoiceResponseInput {
  /** Unique ID assigned to each feedback question (client-defined) */
  questionId: Scalars['String'];
  /** The text of the question */
  questionText: Scalars['String'];
  /** A list of options selected by the user */
  response: Array<Scalars['String']>;
}

export enum OnboardingTaskName {
  HealthInformationExchangePage = 'health_information_exchange_page',
  WelcomeModal = 'welcome_modal',
}

export enum OnboardingTaskStatus {
  Complete = 'complete',
  Incomplete = 'incomplete',
}

export enum OpmEligibilityStatus {
  Active = 'active',
  Error = 'error',
  Inactive = 'inactive',
  Pending = 'pending',
  SelfPay = 'self_pay',
}

export interface PatientAddressInputObject {
  address1?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  destroy?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  isPreferred?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
}

/** Autogenerated input type of PatientDisableMfa */
export interface PatientDisableMfaInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
}

export interface PatientDisplayNameOptions {
  /** Makes the last name appear first (Lastnamerson, Firsty) */
  lastFirst?: InputMaybe<Scalars['Boolean']>;
  middleName?: InputMaybe<Scalars['Boolean']>;
  preferredName?: InputMaybe<Scalars['Boolean']>;
  suffix?: InputMaybe<Scalars['Boolean']>;
}

export enum PatientMedicationStatusEnum {
  Active = 'active',
  Deleted = 'deleted',
  Discontinued = 'discontinued',
  Pending = 'pending',
}

export interface PatientPhoneNumberInputObject {
  _destroy?: InputMaybe<Scalars['Boolean']>;
  ext?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  isPreferred?: InputMaybe<Scalars['Boolean']>;
  kind?: InputMaybe<PatientPhoneNumberKind>;
  number?: InputMaybe<Scalars['String']>;
}

export enum PatientPhoneNumberKind {
  Home = 'home',
  Mobile = 'mobile',
  Other = 'other',
  Work = 'work',
}

/** Autogenerated input type of PatientRegenerateMfaRecoveryCode */
export interface PatientRegenerateMfaRecoveryCodeInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
}

export interface PatientSurveyAnswer {
  answerId?: InputMaybe<Scalars['ID']>;
  answerText?: InputMaybe<Scalars['String']>;
  kind?: InputMaybe<SurveyAnswerKind>;
  surveyQuestionId?: InputMaybe<Scalars['ID']>;
}

/** Enum for PatientTaskType order types */
export enum PatientTaskOrderType {
  BasicFollowUpOrder = 'BasicFollowUpOrder',
  ConnectedDeviceOrder = 'ConnectedDeviceOrder',
  ConsultOrder = 'ConsultOrder',
  GenericFollowUpOrder = 'GenericFollowUpOrder',
  LabOrder = 'LabOrder',
  ProcedureOrder = 'ProcedureOrder',
  SurveyOrder = 'SurveyOrder',
  VaccineOrder = 'VaccineOrder',
  VisitFollowUpOrder = 'VisitFollowUpOrder',
}

export enum PatientTaskSort {
  /** Sorts patient tasks by updated_at date in descending order. */
  UpdatedAtDesc = 'UpdatedAtDesc',
}

/** Enum for PatientTaskType states */
export enum PatientTaskState {
  Assigned = 'assigned',
  Cancelled = 'cancelled',
  Completed = 'completed',
  Declined = 'declined',
  Expired = 'expired',
}

export enum PharmacyMedicationAvailabilityStatusEnum {
  Available = 'available',
  CurrentlyUnavailable = 'currently_unavailable',
  DoesNotFill = 'does_not_fill',
  Unavailable = 'unavailable',
  Unknown = 'unknown',
}

export enum PharmacyMedicationDeliveryPromiseConfidenceEnum {
  High = 'high',
  Low = 'low',
}

export interface PhoneNumberAttributes {
  _destroy?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  ext?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  kind?: InputMaybe<PhoneNumberKind>;
  number?: InputMaybe<Scalars['String']>;
}

export enum PhoneNumberKind {
  BackOffice = 'back_office',
  Fax = 'fax',
  Home = 'home',
  Mobile = 'mobile',
  Other = 'other',
  Pager = 'pager',
  PatientAssistance = 'patient_assistance',
  Work = 'work',
}

/** Autogenerated input type of PostAmazonPharmacyEnrollment */
export interface PostAmazonPharmacyEnrollmentInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  externalId: Scalars['String'];
}

export enum ProblemStatus {
  Current = 'current',
  Mistaken = 'mistaken',
  Rejected = 'rejected',
  Resolved = 'resolved',
  Suspected = 'suspected',
  Unconfirmed = 'unconfirmed',
}

export interface ProfileInfo {
  addressesAttributes?: InputMaybe<Array<PatientAddressInputObject>>;
  emergencyContactsAttributes?: InputMaybe<Array<EmergencyContactInputObject>>;
  employerName?: InputMaybe<Scalars['String']>;
  employerZipCode?: InputMaybe<Scalars['String']>;
  genderDetails?: InputMaybe<Scalars['String']>;
  insurancesManuallyVerifiedAt?: InputMaybe<Scalars['DateTime']>;
  phoneNumbersAttributes?: InputMaybe<Array<PatientPhoneNumberInputObject>>;
  preferredName?: InputMaybe<Scalars['String']>;
  profileImage?: InputMaybe<Scalars['Upload']>;
  pronounGroup?: InputMaybe<Scalars['String']>;
  sex?: InputMaybe<Scalars['String']>;
}

export enum ProgramSystemName {
  HighRiskCareManagement = 'HIGH_RISK_CARE_MANAGEMENT',
  MindsetPlus = 'MINDSET_PLUS',
}

export enum ProviderSortBy {
  LastName = 'LAST_NAME',
}

export enum RecordRequest {
  AuthorizeFamily = 'AUTHORIZE_FAMILY',
  ObtainRecords = 'OBTAIN_RECORDS',
  ReleaseRecords = 'RELEASE_RECORDS',
}

/** Autogenerated input type of RefuseAmazonPharmacySharing */
export interface RefuseAmazonPharmacySharingInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
}

export enum RegistrationWaitlistEnrollmentState {
  Registered = 'registered',
  Waitlisted = 'waitlisted',
}

/** Autogenerated input type of RemoveSelfFromMembershipGroupSubscription */
export interface RemoveSelfFromMembershipGroupSubscriptionInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
}

/** Autogenerated input type of ReportAmazonPharmacyEnrollment */
export interface ReportAmazonPharmacyEnrollmentInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
}

/** Autogenerated input type of RequestMedicalRecords */
export interface RequestMedicalRecordsInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
}

export interface RescheduleAppointmentAttributes {
  appointmentTypeId: Scalars['ID'];
  /** ID of the appointment inventory that will be booked as a part of rescheduling. */
  inventoryId: Scalars['ID'];
  sendSms?: InputMaybe<Scalars['Boolean']>;
  smsNumber?: InputMaybe<Scalars['String']>;
}

/** Autogenerated input type of RescheduleAppointment */
export interface RescheduleAppointmentInput {
  appointmentCancellationReasonId?: InputMaybe<Scalars['ID']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** ID of the appointment that will be cancelled as a part of rescheduling. */
  fromAppointmentId: Scalars['ID'];
  newAppointmentAttributes: RescheduleAppointmentAttributes;
}

/** Autogenerated input type of ResendDirectSignupEmail */
export interface ResendDirectSignupEmailInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  token: Scalars['String'];
}

/** Autogenerated input type of RevokeAbandonedMembershipGroupInvites */
export interface RevokeAbandonedMembershipGroupInvitesInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
}

/** Autogenerated input type of RevokeMembershipGroupInvite */
export interface RevokeMembershipGroupInviteInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  inviteId: Scalars['ID'];
}

export enum RoleEnum {
  Acupuncturist = 'ACUPUNCTURIST',
  Admin = 'ADMIN',
  Allergist = 'ALLERGIST',
  AutomatedTemplateEditor = 'AUTOMATED_TEMPLATE_EDITOR',
  AutoReplyTemplateManager = 'AUTO_REPLY_TEMPLATE_MANAGER',
  BillingAdmin = 'BILLING_ADMIN',
  CalendarAdmin = 'CALENDAR_ADMIN',
  CareManager = 'CARE_MANAGER',
  CareNav = 'CARE_NAV',
  CentralizedAdmin = 'CENTRALIZED_ADMIN',
  CentralBillingSpecialist = 'CENTRAL_BILLING_SPECIALIST',
  CentralSupportSpecialistOne = 'CENTRAL_SUPPORT_SPECIALIST_ONE',
  CentralSupportSpecialistThree = 'CENTRAL_SUPPORT_SPECIALIST_THREE',
  CentralSupportSpecialistTwo = 'CENTRAL_SUPPORT_SPECIALIST_TWO',
  CeClinicalTrialTeam = 'CE_CLINICAL_TRIAL_TEAM',
  ChargeCaptureAndCodingSpecialists = 'CHARGE_CAPTURE_AND_CODING_SPECIALISTS',
  Chiropractic = 'CHIROPRACTIC',
  ChronicCareProvider = 'CHRONIC_CARE_PROVIDER',
  ClientServices = 'CLIENT_SERVICES',
  ClinicalProgramsManager = 'CLINICAL_PROGRAMS_MANAGER',
  ClinicalSupportTeam = 'CLINICAL_SUPPORT_TEAM',
  Clubhouse = 'CLUBHOUSE',
  Colpo = 'COLPO',
  ContactAdmin = 'CONTACT_ADMIN',
  ContentAdmin = 'CONTENT_ADMIN',
  DceProvider = 'DCE_PROVIDER',
  Dermatologist = 'DERMATOLOGIST',
  DiabeticRetinopathyTeam = 'DIABETIC_RETINOPATHY_TEAM',
  DiscountTypeAdmin = 'DISCOUNT_TYPE_ADMIN',
  DuplicateReviewer = 'DUPLICATE_REVIEWER',
  EndometrialBiopsy = 'ENDOMETRIAL_BIOPSY',
  Engineer = 'ENGINEER',
  FamilyMedicine = 'FAMILY_MEDICINE',
  FrontDeskAdmin = 'FRONT_DESK_ADMIN',
  GoogleCepheidProvider = 'GOOGLE_CEPHEID_PROVIDER',
  GoogleNycWellness = 'GOOGLE_NYC_WELLNESS',
  GroupVisitCoordinator = 'GROUP_VISIT_COORDINATOR',
  GroupVisitFacilitator = 'GROUP_VISIT_FACILITATOR',
  Gyn = 'GYN',
  HealthCoach = 'HEALTH_COACH',
  HealthNavigator = 'HEALTH_NAVIGATOR',
  HiResAnoscopy = 'HI_RES_ANOSCOPY',
  Hypnotherapist = 'HYPNOTHERAPIST',
  ImpactProvider = 'IMPACT_PROVIDER',
  IntegrativeHealthLabs = 'INTEGRATIVE_HEALTH_LABS',
  Itstuff = 'ITSTUFF',
  IudInserter = 'IUD_INSERTER',
  IudRemover = 'IUD_REMOVER',
  JaneStreetAdmin = 'JANE_STREET_ADMIN',
  LactationConsultant = 'LACTATION_CONSULTANT',
  Legal = 'LEGAL',
  LicensedPracticalNurse = 'LICENSED_PRACTICAL_NURSE',
  Manager = 'MANAGER',
  Marketer = 'MARKETER',
  MassCancellation = 'MASS_CANCELLATION',
  MediaAndSocialResponseTeam = 'MEDIA_AND_SOCIAL_RESPONSE_TEAM',
  MedicalRecordsAdmin = 'MEDICAL_RECORDS_ADMIN',
  MedicationAdmin = 'MEDICATION_ADMIN',
  MedStudent = 'MED_STUDENT',
  MembershipAdvisor = 'MEMBERSHIP_ADVISOR',
  MemberIdentityAdmin = 'MEMBER_IDENTITY_ADMIN',
  MemberTechnicalSupport = 'MEMBER_TECHNICAL_SUPPORT',
  MenopauseProvider = 'MENOPAUSE_PROVIDER',
  MentalHealth = 'MENTAL_HEALTH',
  MindsetEnterprise = 'MINDSET_ENTERPRISE',
  MindBody = 'MIND_BODY',
  Naturopath = 'NATUROPATH',
  NexplanonInserter = 'NEXPLANON_INSERTER',
  NexplanonRemover = 'NEXPLANON_REMOVER',
  Nutritionist = 'NUTRITIONIST',
  Ob = 'OB',
  OfficeRnNyc = 'OFFICE_RN_NYC',
  Osteopath = 'OSTEOPATH',
  OsteopathOnly = 'OSTEOPATH_ONLY',
  PanelManagementAdmin = 'PANEL_MANAGEMENT_ADMIN',
  Papper = 'PAPPER',
  ParetoAdmin = 'PARETO_ADMIN',
  Pcp = 'PCP',
  PeakmedCss = 'PEAKMED_CSS',
  Pediatrics = 'PEDIATRICS',
  PediatricsAdmin = 'PEDIATRICS_ADMIN',
  PedsUrgentCare = 'PEDS_URGENT_CARE',
  Pharmacist = 'PHARMACIST',
  Phlebotomist = 'PHLEBOTOMIST',
  PhysicalTherapy = 'PHYSICAL_THERAPY',
  PreconceptionCounseling = 'PRECONCEPTION_COUNSELING',
  PrenatalAdmin = 'PRENATAL_ADMIN',
  PrenatalUltrasound = 'PRENATAL_ULTRASOUND',
  ProblemTypeAdmin = 'PROBLEM_TYPE_ADMIN',
  Provider = 'PROVIDER',
  PsychiatricServices = 'PSYCHIATRIC_SERVICES',
  RadiologicTechnologist = 'RADIOLOGIC_TECHNOLOGIST',
  RespiratorProvider = 'RESPIRATOR_PROVIDER',
  Rn = 'RN',
  SexualAndReproductiveHealthCss = 'SEXUAL_AND_REPRODUCTIVE_HEALTH_CSS',
  SpacexWellness = 'SPACEX_WELLNESS',
  SportsMedicine = 'SPORTS_MEDICINE',
  Teacher = 'TEACHER',
  TemplateEditor = 'TEMPLATE_EDITOR',
  Ucp = 'UCP',
  UcsfOb = 'UCSF_OB',
  UltrasoundTechnician = 'ULTRASOUND_TECHNICIAN',
  UnlicensedMedicalAssistant = 'UNLICENSED_MEDICAL_ASSISTANT',
  UrgentCareProvider = 'URGENT_CARE_PROVIDER',
  Vaccinator = 'VACCINATOR',
  VaccineDocumenter = 'VACCINE_DOCUMENTER',
  VirtualPhysician = 'VIRTUAL_PHYSICIAN',
}

/** Attributes for submitting patient input from a routing step */
export interface RoutingStepInput {
  appointmentType?: InputMaybe<AppointmentTypeInput>;
  appointmentTypeId?: InputMaybe<Scalars['ID']>;
  appointmentTypeLocked?: InputMaybe<Scalars['Boolean']>;
  attestedLocationId?: InputMaybe<Scalars['ID']>;
  careOptionId?: InputMaybe<Scalars['String']>;
  careOptionLocked?: InputMaybe<Scalars['Boolean']>;
  careOptionName?: InputMaybe<Scalars['String']>;
  /** The case type of the Treat Me Now. */
  caseType?: InputMaybe<Scalars['String']>;
  /** The short name for the licensing body selected by the user. */
  licensingBodyShortName?: InputMaybe<Scalars['String']>;
  /** @deprecated Use careOptionName instead */
  optionName?: InputMaybe<Scalars['String']>;
  serviceAreaId?: InputMaybe<Scalars['ID']>;
  /** Deprecated, do not use. To be removed. */
  surveyId?: InputMaybe<Scalars['ID']>;
  surveySystemName?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  topicBasedGuidanceEnabled?: InputMaybe<Scalars['Boolean']>;
  topicName?: InputMaybe<Scalars['String']>;
}

/** Autogenerated input type of SavePatientPharmacy */
export interface SavePatientPharmacyInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  pharmacyId: Scalars['ID'];
  setAsPreferred?: InputMaybe<Scalars['Boolean']>;
}

/** Autogenerated input type of SelectServiceArea */
export interface SelectServiceAreaInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  serviceAreaId: Scalars['ID'];
}

/** Autogenerated input type of SendAppLink */
export interface SendAppLinkInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
}

/** Autogenerated input type of SendForgotPasswordEmail */
export interface SendForgotPasswordEmailInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  /** Set to true if the user is in the process of resetting their password for the purposes of enabling MFA. */
  mfaVerification?: InputMaybe<Scalars['Boolean']>;
  usePreferredEmail?: InputMaybe<Scalars['Boolean']>;
}

/** Autogenerated input type of SendMembershipGroupInvite */
export interface SendMembershipGroupInviteInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
}

export enum SendOrReceive {
  NotApplicable = 'NOT_APPLICABLE',
  Receive = 'RECEIVE',
  Send = 'SEND',
}

export enum Sentiment {
  Negative = 'NEGATIVE',
  Positive = 'POSITIVE',
}

export interface SentimentResponseInput {
  /** Unique ID assigned to each feedback question (client-defined) */
  questionId: Scalars['String'];
  /** The text of the question */
  questionText: Scalars['String'];
  /** Response from the user, either positive or negative */
  response: Sentiment;
}

/** Autogenerated input type of SignLegalDocument */
export interface SignLegalDocumentInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
}

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum Source {
  System = 'SYSTEM',
  User = 'USER',
}

/** Autogenerated input type of StoreAccessToken */
export interface StoreAccessTokenInput {
  accessToken: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
}

export enum SurveyAnswerCheckboxCustomBehavior {
  DefaultSelected = 'default_selected',
  SelectAll = 'select_all',
  SelectAllExcluded = 'select_all_excluded',
  SelectNone = 'select_none',
}

export enum SurveyAnswerDatePickerCustomBehavior {
  Future = 'future',
  MonthYearOnly = 'month_year_only',
  Past = 'past',
}

export enum SurveyAnswerKind {
  BinaryRadio = 'binary_radio',
  Boolean = 'boolean',
  Checkbox = 'checkbox',
  DatePicker = 'date_picker',
  DateRange = 'date_range',
  DateString = 'date_string',
  FreeText = 'free_text',
  FsaHsaGridInput = 'fsa_hsa_grid_input',
  HorizontalRadio = 'horizontal_radio',
  MultiSelect = 'multi_select',
  Radio = 'radio',
  Scale = 'scale',
  Select = 'select',
}

/** Autogenerated input type of SwitchToRemoteAppointment */
export interface SwitchToRemoteAppointmentInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  sendSms: Scalars['Boolean'];
  smsNumber?: InputMaybe<Scalars['String']>;
}

export interface SystemMessagePayloadInput {
  content: Scalars['String'];
  requiresSystemMessageInput: Scalars['Boolean'];
  type: Scalars['String'];
  urgency: SystemMessageUrgency;
}

export enum SystemMessageUrgency {
  Emergency = 'emergency',
  NotUrgent = 'not_urgent',
  Urgent = 'urgent',
}

export enum TimelinePostSort {
  /** Sorts posts by sent date. If a post has comments, the most recent sent date is used for sorting. If a post does not have comments, the post's sent date is used for sorting. */
  RecentActivity = 'RecentActivity',
}

/** Autogenerated input type of ToucanContinueConversation */
export interface ToucanContinueConversationInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** ID of the current conversation (UUID) */
  conversationId: Scalars['String'];
  /** Dialog turn with the payload populated with the user's response */
  dialogTurn: ToucanDialogTurnInput;
}

export interface ToucanDialogTurnInput {
  /** ID of the current dialog turn (UUID) */
  dialogTurnId: Scalars['String'];
  /** Not used since the user won't be the source of generated responses */
  generatedResponsePayload?: InputMaybe<GeneratedResponsePayloadInput>;
  /** If true, indicates that IHS considers this the end of the conversation. */
  isTerminal: Scalars['Boolean'];
  /** Multiple choice payload populated with the user's answer(s) */
  multipleChoicePayload?: InputMaybe<MultipleChoicePayloadInput>;
  /** Set to "USER" to indicate this dialog turn is a response from the user. */
  source: Source;
  /** Not used since the user won't be the source of system messages */
  systemMessagePayload?: InputMaybe<SystemMessagePayloadInput>;
  /** Free text payload populated with response text from the user's */
  userFreetextPayload?: InputMaybe<UserFreeTextPayloadInput>;
}

export interface ToucanFeedbackResponseInput {
  /** Zero or more responses for free-text feedback questions */
  freetextResponse?: InputMaybe<Array<FreetextResponseInput>>;
  /** Zero or more responses for multiple choice feedback questions */
  multipleChoiceResponse?: InputMaybe<Array<MultipleChoiceResponseInput>>;
  /** Zero or more responses for sentiment feedback questions */
  sentimentResponse?: InputMaybe<Array<SentimentResponseInput>>;
}

/** Autogenerated input type of ToucanGenerate */
export interface ToucanGenerateInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** ID of the current conversation (UUID) */
  conversationId: Scalars['String'];
  /** ID of the dialog turn whose content to be generated (UUID) */
  dialogTurnId: Scalars['String'];
}

/** Autogenerated input type of ToucanNewConversation */
export interface ToucanNewConversationInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The initial prompt to start a new conversation */
  content: Scalars['String'];
}

/** Autogenerated input type of ToucanRegisterFeedback */
export interface ToucanRegisterFeedbackInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** ID of the current conversation (UUID) */
  conversationId: Scalars['String'];
  /** ID of the dialog turn this feedback applies to */
  dialogTurnId: Scalars['String'];
  /** Feedback responses from the user */
  feedbackResponse: ToucanFeedbackResponseInput;
}

export interface UdiCarrierAttributes {
  deviceIdentifier?: InputMaybe<Scalars['String']>;
}

/** Autogenerated input type of UnlinkFamilyAccountMember */
export interface UnlinkFamilyAccountMemberInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** ID of the family account member to unlink */
  familyAccountMemberId: Scalars['ID'];
}

/** Autogenerated input type of UpdateBookingSessionReasonForVisit */
export interface UpdateBookingSessionReasonForVisitInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  reasonForVisit: Scalars['String'];
}

/** Autogenerated input type of UpdateDefaultCopayStripeIdForPatient */
export interface UpdateDefaultCopayStripeIdForPatientInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  stripePaymentMethodId: Scalars['String'];
}

/** Autogenerated input type of UpdateHieOptIn */
export interface UpdateHieOptInInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The version of the Health Information Exchange Legal Text that was displayed to the user. */
  healthInformationExchangeLegalTextVersion: Scalars['Int'];
  healthInformationExchangeOptIn: Scalars['Boolean'];
}

/** Autogenerated input type of UpdateMfaEmailSettings */
export interface UpdateMfaEmailSettingsInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The email to use for MFA */
  email: Scalars['String'];
}

/** Autogenerated input type of UpdateMfaPhoneSettings */
export interface UpdateMfaPhoneSettingsInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The phone number to use for MFA */
  phoneNumber: Scalars['String'];
  /** The preferred delivery method to use for MFA. Valid options are 'sms' or 'voice' */
  preferredDeliveryMethod: MfaFactor;
}

/** Autogenerated input type of UpdatePatient */
export interface UpdatePatientInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  profileInfo: ProfileInfo;
}

/** Autogenerated input type of UpdatePatientOnboardingTask */
export interface UpdatePatientOnboardingTaskInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  name: OnboardingTaskName;
  status: OnboardingTaskStatus;
}

/** Autogenerated input type of UpdatePatientPcp */
export interface UpdatePatientPcpInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  officeId: Scalars['ID'];
  providerId: Scalars['ID'];
}

/** Autogenerated input type of UpdatePatientPreferences */
export interface UpdatePatientPreferencesInput {
  acceptsClinicalEmails?: InputMaybe<Scalars['Boolean']>;
  acceptsPatientTaskEmails?: InputMaybe<Scalars['Boolean']>;
  acceptsPatientTaskPush?: InputMaybe<Scalars['Boolean']>;
  aiAssistantEnabled?: InputMaybe<Scalars['Boolean']>;
  avsHideWeight?: InputMaybe<Scalars['Boolean']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  connectedDevicesOptIn?: InputMaybe<Scalars['Boolean']>;
  fullTextNotifications?: InputMaybe<Scalars['Boolean']>;
  needsLanguageInterpreter?: InputMaybe<Scalars['Boolean']>;
}

/** Autogenerated input type of UpdatePatientTask */
export interface UpdatePatientTaskInput {
  appointmentId?: InputMaybe<Scalars['ID']>;
  cityName?: InputMaybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  feedback?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  message?: InputMaybe<Scalars['String']>;
  month?: InputMaybe<Scalars['String']>;
  providerOrFacilityName?: InputMaybe<Scalars['String']>;
  state: PatientTaskState;
  year?: InputMaybe<Scalars['String']>;
}

/** Autogenerated input type of UpdatePendingDirectSignupAccount */
export interface UpdatePendingDirectSignupAccountInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  token: Scalars['String'];
}

/** Autogenerated input type of UpdateRegistrationWaitlistEnrollment */
export interface UpdateRegistrationWaitlistEnrollmentInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  patientId: Scalars['ID'];
  serviceAreaCode: Scalars['String'];
}

/** Autogenerated input type of UpdateRoutingMetadata */
export interface UpdateRoutingMetadataInput {
  channelSelected?: InputMaybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  encounterId?: InputMaybe<Scalars['ID']>;
  uuid: Scalars['ID'];
}

/** Autogenerated input type of UpdateStripePaymentMethodForPatient */
export interface UpdateStripePaymentMethodForPatientInput {
  billingName?: InputMaybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  expMonth?: InputMaybe<Scalars['Int']>;
  expYear?: InputMaybe<Scalars['Int']>;
  nickname?: InputMaybe<Scalars['String']>;
  stripePaymentMethodId: Scalars['String'];
}

/** Autogenerated input type of UpdateUserNotification */
export interface UpdateUserNotificationInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  read?: InputMaybe<Scalars['Boolean']>;
}

export interface UserFreeTextPayloadInput {
  /** Prompt text from the user to be sent to IHS-Service */
  content?: InputMaybe<Scalars['String']>;
  /** Set to true as returned by toucanNewConversation or toucanContinueConversation */
  requiresUserFreeTextInput: Scalars['Boolean'];
  /** must be "userFreeText" */
  type: Scalars['String'];
}

/** Autogenerated input type of ValidateClaimCode */
export interface ValidateClaimCodeInput {
  claimCode: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  purchaseRef?: InputMaybe<Scalars['String']>;
}

/** Autogenerated input type of VerifyPatientPassword */
export interface VerifyPatientPasswordInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The password to verify that the user is authenticated */
  currentPassword: Scalars['String'];
}
