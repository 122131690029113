import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AppointmentAnalyticsProperty } from '@app/core/appointment-analytics-base.service';
import { LinksService } from '@app/core/links.service';
import { Membership } from '@app/core/membership';
import { KESTREL_MP_FLOW_VERSION } from '@app/core/mixpanel.constants';
import { PostRegistrationAction } from '@app/registration/graph/edges/membership-update-to-book-appointment.edge';
import { GraphNavigationArgs } from '@app/registration/graph/graph-navigation.service';
import { NodeUrlMappingService } from '@app/registration/graph/node-url-mapping.service';
import { RegistrationNode } from '@app/registration/graph/nodes/registration.node';
import { AppointmentBookingSource } from '@app/shared/appointment-booking-source';

@Injectable({
  providedIn: 'root',
})
export class BookAppointmentNode extends RegistrationNode {
  constructor(private linksService: LinksService, nodeUrlMappingService: NodeUrlMappingService, router: Router) {
    super(nodeUrlMappingService, router);
    this.edges = [];
  }

  execute(args?: GraphNavigationArgs): void {
    const limitedAccessMembershipBooking = this.limitedAccessMembershipBooking(args);

    this.router.navigate([this.linksService.getCare], {
      queryParams: {
        ...(limitedAccessMembershipBooking && {
          source: AppointmentBookingSource.LegalDocumentPage,
          flowVersion: KESTREL_MP_FLOW_VERSION,
          flow: AppointmentAnalyticsProperty.BookingFlow,
          module: AppointmentAnalyticsProperty.BookingPageModule,
        }),
      },
      queryParamsHandling: limitedAccessMembershipBooking ? 'merge' : 'preserve',
    });
  }

  private limitedAccessMembershipBooking(args?: GraphNavigationArgs): boolean {
    return (
      Membership.hasLimitedAccessPlanType(args?.membershipInfo?.membershipType ?? null) &&
      args?.postRegistrationAction === PostRegistrationAction.BOOKING &&
      !!args?.serviceAreaCode
    );
  }
}
