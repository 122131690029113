import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { ApolloQueryResult } from '@apollo/client/core';
import { Subject, Observable, combineLatest } from 'rxjs';
import { map, tap, takeUntil } from 'rxjs/operators';

import { FeatureFlags } from '@app/core/feature-flags/feature-flags';
import { LaunchDarklyService } from '@app/core/feature-flags/launchdarkly.service';
import { LegalDocumentsService, TermsOfService } from '@app/core/legal-documents';
import { MarkdownService } from '@app/core/markdown.service';
import { ContentBlock } from '@app/home/__generated__/content-block-graphql.service.types';
import { ContentBlockGraphQL } from '@app/home/content-block-graphql.service';

import { ToSUpdateModalComponent } from '../tos-update-modal/tos-update-modal.component';

@Component({
  selector: 'om-tos-update-container',
  templateUrl: './tos-update-container.component.html',
  styleUrls: [],
})
export class ToSUpdateContainerComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('tosUpdateModal') tosUpdateModal: ToSUpdateModalComponent;

  private unsignedTosTypes = new Map<string, TermsOfService>();
  private destroy$ = new Subject<void>();
  private showModal$: Observable<boolean>;
  private notificationEnabledFlag$: Observable<boolean>;
  protected modalContent$: Observable<SafeHtml>;

  private readonly fallbackModalContent =
    'We updated our Membership Terms of Services and Medical Terms of Service as of 2/28/2025. ' +
    'The Membership Terms of Service is now named the One Medical Terms of Use. Please review the ' +
    'updated terms carefully – the [One Medical Terms of Use](https://www.onemedical.com/membership-terms/) ' +
    'applies to your use of our Services as defined therein, and the [Medical Terms of Service]' +
    '(https://www.onemedical.com/medical-terms-service) applies to your use of our Medical Services as defined therein.' +
    '\n\n\nBy continuing, you agree to the One Medical Terms of Use and the Medical Terms of Service';

  constructor(
    private legalDocumentsService: LegalDocumentsService,
    private markdownService: MarkdownService,
    private contentBlockGraphQL: ContentBlockGraphQL,
    private launchDarklyService: LaunchDarklyService,
  ) {}

  ngOnInit() {
    this.showModal$ = this.legalDocumentsService.getForSelf().valueChanges.pipe(
      tap((termsOfServices: TermsOfService[]) => this.updateUnsignedTosType(termsOfServices)),
      map((termsOfServices: TermsOfService[]) => this.hasUnsignedTermsOfService(termsOfServices)),
    );

    this.notificationEnabledFlag$ = this.launchDarklyService.featureFlag$(
      FeatureFlags.HOMESCREEN_NOTIFY_TERMS_OF_SERVICE,
      false,
    );

    this.modalContent$ = this.fetchContentBlock();
  }

  ngAfterViewInit() {
    combineLatest([this.showModal$, this.modalContent$, this.notificationEnabledFlag$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([showModal, modalContent, notificationEnabled]) => {
        if (showModal && modalContent && notificationEnabled) {
          this.tosUpdateModal.open();
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private fetchContentBlock(): Observable<SafeHtml> {
    return this.contentBlockGraphQL
      .fetch({
        section: 'terms-of-service',
        page: null,
        serviceAreaId: null,
        title: 'update-notification',
      })
      .pipe(
        map((result: ApolloQueryResult<ContentBlock>) =>
          this.markdownService.convert(result.data.contentBlock?.copy || this.fallbackModalContent),
        ),
      );
  }

  handleDismissed(acknowledged: boolean) {
    this.unsignedTosTypes.forEach((value: TermsOfService) => {
      this.legalDocumentsService.signForSelf(value).subscribe(() => acknowledged);
    });
  }

  private updateUnsignedTosType(termsOfServices: TermsOfService[]) {
    termsOfServices.filter(tos => tos.signed === false).forEach(tos => this.unsignedTosTypes.set(tos.type, tos));
  }

  private hasUnsignedTermsOfService(termsOfServices: TermsOfService[]) {
    return termsOfServices.filter(tos => tos.signed === false).length > 0;
  }
}
