import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of as observableOf } from 'rxjs';
import { map } from 'rxjs/operators';

import { StartToMembershipUpdateEdge } from '@app/registration/graph/edges/start-to-membership-update.edge';
import { StartToMfaEdge } from '@app/registration/graph/edges/start-to-mfa.edge';
import { StartToServiceAreaEdge } from '@app/registration/graph/edges/start-to-service-area.edge';
import { StartToTermsOfServiceEdge } from '@app/registration/graph/edges/start-to-terms-of-service.edge';
import { NodeUrlMappingService } from '@app/registration/graph/node-url-mapping.service';
import { RegistrationNode } from '@app/registration/graph/nodes/registration.node';
import { AssignMembershipGroupSeatGraphqlService } from '@app/registration/login-with-amazon/assign-membership-group-seat-graphql.service';

@Injectable({
  providedIn: 'root',
})
export class StartNode extends RegistrationNode {
  constructor(
    private assignMembershipGroupSeatGraphQLService: AssignMembershipGroupSeatGraphqlService,
    private startToMfaEdge: StartToMfaEdge,
    private startToServiceAreaEdge: StartToServiceAreaEdge,
    private startToTermsOfServiceEdge: StartToTermsOfServiceEdge,
    private startToMembershipUpdateEdge: StartToMembershipUpdateEdge,
    nodeUrlMappingService: NodeUrlMappingService,
    router: Router,
  ) {
    super(nodeUrlMappingService, router);
    this.edges = [startToServiceAreaEdge, startToTermsOfServiceEdge, startToMfaEdge, startToMembershipUpdateEdge];
    this.isAsync = true;
  }

  execute$({ claimCode, lwaPurchaseRef }: { claimCode?: string; lwaPurchaseRef?: string } = {}): Observable<void> {
    if (!claimCode) {
      return observableOf(undefined);
    }

    return this.assignMembershipGroupSeatGraphQLService
      .mutate({ input: { claimCode, lwaPurchaseRef } })
      .pipe(map(() => undefined));
  }
}
