import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, map, Observable, of as observableOf } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';

import { Membership } from '@app/core/membership';
import { MembershipAction, MembershipService } from '@app/core/membership.service';
import { BreadcrumbCategory, SentryMetricService } from '@app/core/metrics/sentry-metric.service';
import { LOGIN_WITH_AMAZON_MP_FLOW_VERSION, MODULE_LEGAL_DOCUMENT_PAGE } from '@app/core/mixpanel.constants';
import { RegistrationAnalyticsService } from '@app/registration';
import { MembershipUpdateToHomepageEdge } from '@app/registration/graph/edges/membership-update-to-homepage.edge';
import { MembershipUpdateToPrepaidConfirmationEdge } from '@app/registration/graph/edges/membership-update-to-prepaid-confirmation.edge';
import { MembershipUpdateToPrepaidInviteEdge } from '@app/registration/graph/edges/membership-update-to-prepaid-invite.edge';
import { GraphNavigationArgs, GraphNavigationService } from '@app/registration/graph/graph-navigation.service';
import { NodeUrlMappingService } from '@app/registration/graph/node-url-mapping.service';
import { MarkRegCompleteGraphQLService } from '@app/registration/graph/nodes/mark-reg-complete-graphql.service';
import { RegistrationNode } from '@app/registration/graph/nodes/registration.node';
import { MembershipStatusGraphqlService } from '@app/registration/membership-status-graphql.service';
import { ClaimCodeValidationError, PrepaidEnrollmentService } from '@app/registration/prepaid-enrollment.service';
import { Nullable } from '@app/utils/types';

import { MembershipUpdateToBookAppointmentEdge } from '../edges/membership-update-to-book-appointment.edge';

@Injectable({
  providedIn: 'root',
})
export class MembershipUpdateNode extends RegistrationNode {
  private readonly METRIC_NAME_PREFIX = 'membership_update_node';

  constructor(
    private graphNavigationService: GraphNavigationService,
    private membershipService: MembershipService,
    private membershipUpdateToHomepageEdge: MembershipUpdateToHomepageEdge,
    private membershipUpdateToPrepaidConfirmationEdge: MembershipUpdateToPrepaidConfirmationEdge,
    private membershipUpdateToBookAppointmentEdge: MembershipUpdateToBookAppointmentEdge,
    private membershipUpdateToPrepaidInviteEdge: MembershipUpdateToPrepaidInviteEdge,
    private membershipStatusGraphqlService: MembershipStatusGraphqlService,
    private prepaidEnrollmentService: PrepaidEnrollmentService,
    private registrationAnalyticsService: RegistrationAnalyticsService,
    private markRegCompleteGraphQLService: MarkRegCompleteGraphQLService,
    private sentryMetricService: SentryMetricService,
    nodeUrlMappingService: NodeUrlMappingService,
    router: Router,
  ) {
    super(nodeUrlMappingService, router);
    this.edges = [
      membershipUpdateToPrepaidInviteEdge,
      membershipUpdateToPrepaidConfirmationEdge,
      membershipUpdateToBookAppointmentEdge,
      membershipUpdateToHomepageEdge,
    ];
    this.isAsync = true;
  }

  execute$(args: GraphNavigationArgs): Observable<void> {
    const { claimCode } = args;
    const membershipType = args.membershipInfo?.membershipType;

    if (Membership.hasLimitedAccessPlanType(membershipType ?? null)) {
      return this.markRegCompleteGraphQLService.mutate().pipe(
        tap(() => this.logRegComplete({ membershipType, claimCode })),
        map(() => undefined),
      );
    }

    return this.createOrRenewMembership(claimCode);
  }

  private createOrRenewMembership(claimCode?: string) {
    return this.membershipService
      .createOrRenewConsumerOrAmazonMembership({
        claimCode,
        membershipAction: MembershipAction.New,
      })
      .pipe(
        map(() => undefined),
        catchError(error => observableOf(error)),
        switchMap(error => {
          if (error) {
            return this.handleMembershipError$(error, claimCode);
          }

          return this.membershipStatusGraphqlService.fetch().pipe(
            filter(result => !!result.data?.membership?.isActive),
            map(result => {
              const status = result.data?.membership?.status;

              if (status) {
                this.graphNavigationService.updateMembershipStatus(status);
              }

              return status;
            }),
            tap({
              next: membershipStatus => {
                this.logMembershipStatusUpdate({ membershipStatus });
                this.registrationAnalyticsService.graphNavigationMembershipActivated({
                  module: MODULE_LEGAL_DOCUMENT_PAGE,
                  ...this.graphNavigationService.analyticsProperties,
                });
              },
              error: membershipStatusFetchError => this.handleMembershipStatusError(membershipStatusFetchError),
            }),
          );
        }),
        map(() => undefined),
      );
  }

  private handleMembershipError$(error: any, claimCode?: string): Observable<void> {
    this.sentryMetricService.error(
      {
        operationName: `${this.METRIC_NAME_PREFIX}_update_membership`,
      },
      error,
    );

    return this.prepaidEnrollmentService
      .getErrorRoute$({
        error: new ClaimCodeValidationError(error),
        source: 'Login with Amazon',
        flowVersion: LOGIN_WITH_AMAZON_MP_FLOW_VERSION,
        claimCode,
      })
      .pipe(
        map(errorUrl => {
          this.router.navigateByUrl(errorUrl);
        }),
      );
  }

  private handleMembershipStatusError(error: any) {
    this.sentryMetricService.error(
      {
        operationName: `${this.METRIC_NAME_PREFIX}_membership_status_fetch`,
      },
      error,
    );
  }

  private logMembershipStatusUpdate({ membershipStatus }: { membershipStatus: Nullable<string> }): void {
    this.sentryMetricService.breadcrumb({
      message: `${this.METRIC_NAME_PREFIX}_membership_status_update`,
      category: BreadcrumbCategory.GraphNavigation,
      data: { membershipStatus },
    });
  }

  private logRegComplete({
    membershipType,
    claimCode,
  }: {
    membershipType: Nullable<string>;
    claimCode?: string;
  }): void {
    this.sentryMetricService.breadcrumb({
      message: `${this.METRIC_NAME_PREFIX}_mark_reg_complete`,
      category: BreadcrumbCategory.GraphNavigation,
      data: {
        membershipType,
        claimCode,
      },
    });
  }
}
