import { Injectable } from '@angular/core';
import { Query, gql } from 'apollo-angular';

import {
  PatientRegistrationStatus,
  PatientRegistrationStatusVariables,
} from '@app/registration/__generated__/patient-registration-status-graphql.service.types';

@Injectable({
  providedIn: 'root',
})
export class PatientRegistrationStatusGraphqlService extends Query<
  PatientRegistrationStatus,
  PatientRegistrationStatusVariables
> {
  document = gql`
    query PatientRegistrationStatus {
      patient {
        id
        isRegComplete
      }
    }
  `;
}
