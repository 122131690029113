import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { combineLatest, from, Observable, of as observableOf } from 'rxjs';
import { map, catchError, switchMap, take } from 'rxjs/operators';

import {
  ManageInsuranceSource,
  ManageInsuranceQueryParams,
} from '@app/appointment/manage-insurance/manage-insurance.component';
import { PrimaryInsuranceAndMembershipIsDpcGraphQLService } from '@app/appointment/primary-insurance-and-membership-is-dpc-graphql.service';
import { FeatureFlags, FeatureFlagVariantsWithOn } from '@app/core/feature-flags/feature-flags';
import { LaunchDarklyService } from '@app/core/feature-flags/launchdarkly.service';
import { LinksService } from '@app/core/links.service';
import { MembershipService } from '@app/core/membership.service';
import { UserService } from '@app/core/user.service';

import { PaymentCaptureService } from '../payment-capture/payment-capture.service';
import { User } from '../user';
import { PrimaryInsuranceGraphQLService } from './primary-insurance-graphql.service';

export const InsuranceStatus = {
  SELF_PAY: 'Self Pay',
  PENDING: 'Pending',
  VERIFIED: 'Verified',
} as const;
export type InsuranceStatus = typeof InsuranceStatus[keyof typeof InsuranceStatus];

export interface InsuranceCaptureValues {
  variant: FeatureFlagVariantsWithOn;
  primaryInsuranceStatus: string | undefined;
}

export interface InsuranceData {
  insuranceStatus: InsuranceStatus;
  insuranceCompany?: string | null;
  memberId?: string | null;
  copay?: number | null;
}

export interface InsuranceCaptureNavigateParams {
  insuranceCaptureFlowEnabled: boolean;
  source?: ManageInsuranceSource;
  confirmationId?: string;
  replaceUrl?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class InsuranceCaptureService {
  user$: Observable<User>;

  readonly OFF_STATE: InsuranceCaptureValues = {
    variant: FeatureFlagVariantsWithOn.OFF,
    primaryInsuranceStatus: undefined,
  };

  constructor(
    private readonly launchDarklyService: LaunchDarklyService,
    private readonly primaryInsuranceAndMembershipIsDpcGraphQLService: PrimaryInsuranceAndMembershipIsDpcGraphQLService,
    private readonly primaryInsuranceGraphQLService: PrimaryInsuranceGraphQLService,
    private readonly userService: UserService,
    private readonly membershipService: MembershipService,
    private readonly router: Router,
    private readonly links: LinksService,
    private readonly paymentCaptureService: PaymentCaptureService,
  ) {
    this.user$ = this.userService.getUser();
  }

  updateLdUserAndGetInsuranceCaptureFlagEnabled$(isBillableVisit: boolean): Observable<boolean> {
    return this.updateLdUserAndGetInsuranceCaptureValues$(isBillableVisit).pipe(
      map(
        result =>
          result.variant === FeatureFlagVariantsWithOn.ON_VARIANT || result.variant === FeatureFlagVariantsWithOn.ON,
      ),
    );
  }

  updateLdUserAndGetInsuranceCaptureValues$(isBillableVisit: boolean): Observable<InsuranceCaptureValues> {
    if (!isBillableVisit) {
      return observableOf({ variant: FeatureFlagVariantsWithOn.OFF, primaryInsuranceStatus: undefined });
    }

    return combineLatest([
      this.user$,
      this.primaryInsuranceAndMembershipIsDpcGraphQLService.fetch(),
      this.membershipService.getMembership(),
    ]).pipe(
      switchMap(([user, graphqlResult, membership]) => {
        const primaryInsuranceStatus = graphqlResult.data.patient?.primaryInsurance.verificationStatus;
        const isDpc = graphqlResult.data.membership?.isDpc ?? false;

        if (isDpc) {
          return observableOf({ variant: FeatureFlagVariantsWithOn.OFF, primaryInsuranceStatus });
        }

        return from(
          this.launchDarklyService.updateUser({
            user,
            membership,
            customAttributes: { primaryInsuranceStatus },
          }),
        ).pipe(
          take(1),
          switchMap(() => this.insuranceCaptureFlagVariant$()),
          map(variant => ({ variant, primaryInsuranceStatus })),
        );
      }),
      catchError(() => observableOf({ variant: FeatureFlagVariantsWithOn.OFF, primaryInsuranceStatus: undefined })),
    );
  }

  insuranceCaptureFullPageFlagEnabled$(): Observable<boolean> {
    return this.launchDarklyService
      .featureFlag$<FeatureFlagVariantsWithOn>(
        FeatureFlags.INSURANCE_CAPTURE_WEB_FULL_PAGE,
        FeatureFlagVariantsWithOn.OFF,
      )
      .pipe(map(result => result === FeatureFlagVariantsWithOn.ON_VARIANT || result === FeatureFlagVariantsWithOn.ON));
  }

  insuranceCaptureCarrierDropdownSearchFlagEnabled$(): Observable<boolean> {
    return this.launchDarklyService
      .featureFlag$<FeatureFlagVariantsWithOn>(
        FeatureFlags.INSURANCE_CAPTURE_CARRIER_DROPDOWN_SEARCH,
        FeatureFlagVariantsWithOn.OFF,
      )
      .pipe(map(result => result === FeatureFlagVariantsWithOn.ON_VARIANT || result === FeatureFlagVariantsWithOn.ON));
  }

  insuranceCaptureFlowAfterInventorySelectionEnabled$(isBillableVisit: boolean): Observable<boolean> {
    return this.insuranceCaptureFlowAfterInventorySelectionValues$(isBillableVisit).pipe(
      map(
        result =>
          result.variant === FeatureFlagVariantsWithOn.ON_VARIANT || result.variant === FeatureFlagVariantsWithOn.ON,
      ),
    );
  }

  insuranceCaptureFlowAfterInventorySelectionValues$(isBillableVisit: boolean): Observable<InsuranceCaptureValues> {
    if (!isBillableVisit) {
      return observableOf(this.OFF_STATE);
    }

    return combineLatest([
      this.launchDarklyService.featureFlag$<FeatureFlagVariantsWithOn>(
        FeatureFlags.INSURANCE_CAPTURE_WEB_FULL_PAGE,
        FeatureFlagVariantsWithOn.OFF,
      ),
      this.primaryInsuranceAndMembershipIsDpcGraphQLService.fetch({}, { fetchPolicy: 'network-only' }),
      this.paymentCaptureService.getPaymentCaptureFlagM2Enabled$(),
    ]).pipe(
      map(([flagResult, graphqlResult, paymentCaptureFlagM2Enabled]) => {
        const primaryInsuranceStatus = graphqlResult.data?.patient?.primaryInsurance.verificationStatus;
        const isDpc = graphqlResult.data.membership?.isDpc ?? false;

        if (isDpc) {
          return this.OFF_STATE;
        }

        if (paymentCaptureFlagM2Enabled) {
          return { variant: flagResult, primaryInsuranceStatus };
        }

        return {
          variant: primaryInsuranceStatus === InsuranceStatus.SELF_PAY ? flagResult : FeatureFlagVariantsWithOn.OFF,
          primaryInsuranceStatus,
        };
      }),
    );
  }

  navigateToInsuranceCaptureIfEnabled(options: InsuranceCaptureNavigateParams) {
    const { insuranceCaptureFlowEnabled, source, confirmationId, replaceUrl } = options;

    if (!insuranceCaptureFlowEnabled) {
      this.router.navigate([this.links.appointmentReview]);
      return;
    }

    this.router.navigate([this.links.manageInsurance], {
      replaceUrl: replaceUrl ?? false,
      queryParams: {
        [ManageInsuranceQueryParams.SOURCE]: source,
        [ManageInsuranceQueryParams.CONFIRMATION_ID]: confirmationId,
      },
    });
  }

  getPrimaryInsurance$(): Observable<InsuranceData> {
    return this.primaryInsuranceGraphQLService.watch({}, { fetchPolicy: 'network-only' }).valueChanges.pipe(
      map(result => {
        const primaryInsurance = result.data?.patient?.primaryInsurance;
        const insuranceStatus = (primaryInsurance?.verificationStatus as InsuranceStatus) || InsuranceStatus.SELF_PAY;

        return {
          insuranceStatus,
          insuranceCompany: primaryInsurance?.name,
          memberId: primaryInsurance?.subscriberNumber,
          copay: primaryInsurance?.copay,
        };
      }),
    );
  }

  private insuranceCaptureFlagVariant$(): Observable<FeatureFlagVariantsWithOn> {
    return this.launchDarklyService.featureFlag$<FeatureFlagVariantsWithOn>(
      FeatureFlags.INSURANCE_CAPTURE_WEB,
      FeatureFlagVariantsWithOn.OFF,
    );
  }
}
