import { Injectable } from '@angular/core';
import { Query, gql } from 'apollo-angular';

import {
  DropOffClaimCode,
  DropOffClaimCodeVariables,
} from '@app/registration/__generated__/drop-off-claim-code-graphql.service.types';

@Injectable({
  providedIn: 'root',
})
export class DropOffClaimCodeGraphqlService extends Query<DropOffClaimCode, DropOffClaimCodeVariables> {
  document = gql`
    query DropOffClaimCode {
      membership {
        id
        dropOffClaimCode
      }
    }
  `;
}
