import { CommonModule } from '@angular/common';
import {
  Component,
  ContentChild,
  Directive,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { Params, RouterModule } from '@angular/router';
import snakeCase from 'lodash-es/snakeCase';
import { Observable } from 'rxjs';

import { appendQueryParamsToPath } from '@app/utils/link.utils';
import { TrackLinkModule } from '@app/utils/track-link.directive';

@Directive({
  selector: '[omgui-standard-card-icon]',
})
export class OmguiStandardCardIconDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({
  selector: '[omgui-standard-card-footer]',
})
export class OmguiStandardCardFooterDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

/** For adding rich content to the bottom of label/header content area */
@Directive({
  selector: '[omgui-standard-card-label-content]',
})
export class OmguiStandardCardLabelContentDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

/** For adding rich content to the bottom of sublabel content area */
@Directive({
  selector: '[omgui-standard-card-sublabel-content]',
})
export class OmguiStandardCardSublabelContentDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

/** For adding a badge at the top-left of the card */
@Directive({
  selector: '[omgui-standard-card-badge]',
})
export class OmguiStandardCardBadgeDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

export enum StandardCardFullWidthAlignment {
  Horizontal = 'horizontal',
  Vertical = 'vertical',
}

export enum StandardCardSizing {
  Responsive = 'responsive',
  Compact = 'compact',
  Compact_MD = 'compact-md',
}

@Component({
  selector: 'omgui-standard-card',
  templateUrl: './omgui-standard-card.component.html',
  styleUrls: ['./omgui-standard-card.component.scss'],
})
export class OmguiStandardCardComponent implements OnInit {
  @ContentChild(OmguiStandardCardIconDirective) icon: OmguiStandardCardIconDirective;

  /**
   * Load rich HTML content into the content area by passing a ng-template with [omgui-standard-card-label-content] or
   * [omgui-standard-card-sublabel-content]
   * For simple text content, use #label and #sublabel
   */
  @ContentChild(OmguiStandardCardLabelContentDirective) labelContent: OmguiStandardCardLabelContentDirective;
  @ContentChild(OmguiStandardCardFooterDirective) footer: OmguiStandardCardFooterDirective;
  @ContentChild(OmguiStandardCardSublabelContentDirective) sublabelContent: OmguiStandardCardSublabelContentDirective;
  @ContentChild(OmguiStandardCardBadgeDirective) badgeContent: OmguiStandardCardBadgeDirective;

  /** The title or main text */
  @Input() label: string;

  /** Subtitle or a short description */
  @Input() sublabel?: string;

  /** Screen readers read all content instead of the default from uniqueLabel */
  @Input() ariaReadAllContent?: boolean;

  /** Internal navigation link */
  @Input() route?: string;

  /** DataCy */
  @Input() dataCy?: string;

  /** External navigation link */
  @Input() href?: string;

  /** Optional function that is executed before the linked resource is navigated to. Meant to be assigned an analytics tracking function */
  @Input() trackLink?: () => Observable<unknown> | undefined;

  /** Query params will be appended to the path */
  @Input() queryParams?: Params;

  /** Direction to align card icon and content */
  @Input() fullWidthAlignment: StandardCardFullWidthAlignment = StandardCardFullWidthAlignment.Horizontal;
  readonly StandardCardFullWidthAlignment = StandardCardFullWidthAlignment;

  @Input() sizing: StandardCardSizing = StandardCardSizing.Responsive;
  readonly StandardCardSizing = StandardCardSizing;

  /** Emits an event on click */
  @Output() clicked: EventEmitter<void> = new EventEmitter<void>();

  /** @ignore */
  hrefWithQueryParams: string;
  uniqueLabel: string;

  onClick(event: Event): void {
    event.preventDefault();
    this.clicked.emit();
  }

  /** @ignore */
  ngOnInit(): void {
    this.hrefWithQueryParams = appendQueryParamsToPath(this.href, this.queryParams);
    this.uniqueLabel = snakeCase(this.label) + '-card-label';
    this.dataCy = this.dataCy + '-group-option';
  }
}

@NgModule({
  declarations: [
    OmguiStandardCardComponent,
    OmguiStandardCardIconDirective,
    OmguiStandardCardFooterDirective,
    OmguiStandardCardLabelContentDirective,
    OmguiStandardCardSublabelContentDirective,
    OmguiStandardCardBadgeDirective,
  ],
  imports: [CommonModule, RouterModule, TrackLinkModule],
  exports: [
    OmguiStandardCardComponent,
    OmguiStandardCardIconDirective,
    OmguiStandardCardFooterDirective,
    OmguiStandardCardLabelContentDirective,
    OmguiStandardCardSublabelContentDirective,
    OmguiStandardCardBadgeDirective,
  ],
})
export class OmguiStandardCardModule {}
