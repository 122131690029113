<ng-template #modalContent let-dismiss="acknowledge">
  <div class="m-3 m-sm-5 p-0 modal-body text-center">
    <div class="mb-4">
      <h2 class="mb-3 px-3 modal-title">We’ve updated our Terms of Service</h2>
      <span [innerHtml]="textContent"></span>
    </div>

    <div class="col-md-7 mx-auto">
      <omgui-button customButtonClass="w-100" aria-label="Continue button" (buttonClick)="acknowledge()">
        Continue
      </omgui-button>
    </div>
  </div>
</ng-template>
