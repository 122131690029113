<ng-container
  *ngIf="customTemplate; else defaultTemplate"
  [ngTemplateOutlet]="customTemplate"
  [ngTemplateOutletContext]="{
    cardId: cardId,
    externalErrors: externalErrors,
    showNicknameField: showNicknameField,
    nickname: nickname,
  }"
>
</ng-container>

<ng-template #defaultTemplate>
  <div
    id="{{ cardId ? 'card-element-' + cardId : 'card-element' }}"
    name="card-element"
    [class.StripeElement--invalid]="externalErrors"
  ></div>
  <div *ngIf="showNicknameField">
    <br />
    <label for="nickname" class="form-label">Nickname for this payment method</label>
    <input type="text" name="nickname" id="nickname-input" [(ngModel)]="nickname" />
  </div>
</ng-template>
