import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'om-tos-update-modal',
  templateUrl: './tos-update-modal.component.html',
  styleUrls: ['./tos-update-modal.component.scss'],
})
export class ToSUpdateModalComponent {
  @ViewChild('modalContent') modalContent: TemplateRef<any>;

  @Input() textContent: SafeHtml;

  @Output() dismissed = new EventEmitter();
  @Output() acknowledged = new EventEmitter();

  modalReference: NgbModalRef;
  protected modalContentBlock$: Observable<SafeHtml>;
  readonly DISMISS_REASON_ACKNOWLEDGED = 'acknowledged';

  constructor(private modalService: NgbModal) {}

  open() {
    this.modalReference = this.modalService.open(this.modalContent, {
      centered: true,
      modalDialogClass: 'modal-redesign no-sm-rounded-bottom',
      ariaLabelledBy: 'tos-update-modal',
    });

    this.modalReference.dismissed.pipe(take(1)).subscribe(reason => {
      if (reason === this.DISMISS_REASON_ACKNOWLEDGED) {
        this.acknowledged.emit();
      } else {
        this.dismissed.emit();
      }
    });
  }

  acknowledge() {
    this.modalReference.dismiss(this.DISMISS_REASON_ACKNOWLEDGED);
  }
}
