import { Injectable } from '@angular/core';
import { Query, gql } from 'apollo-angular';

import { PrimaryInsurance, PrimaryInsuranceVariables } from './__generated__/primary-insurance-graphql.service.types';

@Injectable({
  providedIn: 'root',
})
export class PrimaryInsuranceGraphQLService extends Query<PrimaryInsurance, PrimaryInsuranceVariables> {
  document = gql`
    query PrimaryInsurance {
      patient {
        primaryInsurance {
          copay
          name
          subscriberNumber
          verificationStatus
        }
      }
    }
  `;
}
